import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect } from "react";
import { SamedisFormPageLayoutContext } from "./SamedisFormPageLayout";
import { Grid } from "@mui/material";
import BoxGrid from "../components/BoxGrid";
export const SamedisMobileFormButtonsWrapperClassName = "SamedisFormButtonsPortal";
export const SamedisMobileFormButtonsWrapperMobileClassName = "SamedisFormButtonsMobile";
export const SamedisMobileFormButtonsWrapperDialogClassName = "SamedisFormButtonsDialog";
export const SamedisMobileFormButtonsPortalNullRenderer = (_btns, _dialog) => _jsx(React.Fragment, {});
export const SamedisMobileFormButtonsPortalBoxGridRenderer = (btns, dialog) => (_jsx(BoxGrid, { item: true, xs: 12, className: SamedisMobileFormButtonsWrapperClassName +
        " " +
        (dialog
            ? SamedisMobileFormButtonsWrapperDialogClassName
            : SamedisMobileFormButtonsWrapperMobileClassName), children: btns }));
const SamedisMobileFormButtonsPortalDefaultRenderer = (btns, dialog) => (_jsx(Grid, { item: true, xs: 12, className: SamedisMobileFormButtonsWrapperClassName +
        " " +
        (dialog
            ? SamedisMobileFormButtonsWrapperDialogClassName
            : SamedisMobileFormButtonsWrapperMobileClassName), children: btns }));
const SamedisMobileFormButtonsPortal = (props) => {
    const { render } = props;
    const ctx = useContext(SamedisFormPageLayoutContext);
    if (!ctx)
        throw new Error("SamedisFormPageLayoutContext not set");
    useEffect(() => {
        if (ctx.altButtons == null)
            return;
        ctx.setAltButtonsRendered((prev) => prev + 1);
        return () => {
            ctx.setAltButtonsRendered((prev) => prev - 1);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctx.altButtons]);
    if (!ctx.altButtons)
        return _jsx(_Fragment, {});
    const btns = ctx.altButtons;
    const renderFn = render ?? SamedisMobileFormButtonsPortalDefaultRenderer;
    return _jsx(_Fragment, { children: renderFn(btns, ctx.altButtonsDialog) });
};
export default React.memo(SamedisMobileFormButtonsPortal);
