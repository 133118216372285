import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { Skeleton, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
export const BrowseEntryContext = React.createContext(undefined);
export const useBrowseEntryContext = () => {
    const ctx = useContext(BrowseEntryContext);
    if (!ctx)
        throw new Error("BrowseEntryContext not set");
    return ctx;
};
const useStyles = makeStyles()({
    skeleton: {
        height: "100%",
    },
});
const BrowseEntry = (props) => {
    const { classes } = useStyles();
    const { renderer: Renderer, data } = useBrowseEntryContext();
    const record = data[props.index];
    if (!record)
        return (_jsx("div", { style: props.style, children: _jsx(Skeleton, { variant: "rectangular", className: classes.skeleton }) }));
    if (record instanceof Error)
        return (_jsx("div", { style: props.style, children: _jsx(Typography, { color: "error", children: record.message }) }));
    return (_jsx("div", { style: props.style, children: _jsx(Renderer, { ...record }) }));
};
export default React.memo(BrowseEntry);
