import { ModelDataTypeDecimalCurrencyRendererCC } from "components-care";
const deserialize = (value) => {
    if (value == null)
        return null;
    // cents (int) -> decimal
    return value / 100;
};
const serialize = (value) => {
    if (value == null)
        return null;
    // decimal -> cents (int)
    return (value * 100) | 0;
};
// @ts-expect-error unsupported
ModelDataTypeDecimalCurrencyRendererCC.prototype.serialize = serialize;
// @ts-expect-error unsupported
ModelDataTypeDecimalCurrencyRendererCC.prototype.deserialize = deserialize;
