import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { PageHeaderTypography } from "./CustomTypography";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()({
    mobileTitle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    mobileTitlePlaceholder: {
        opacity: 0,
    },
});
const BasicPageLayoutMobileTitle = (props) => {
    const { title, isMobileActivityDialog } = props;
    const { classes } = useStyles();
    const refPlaceholder = useRef(null);
    const refTitle = useRef(null);
    useEffect(() => {
        const handleResize = () => {
            const title = refTitle.current;
            const placeholder = refPlaceholder.current;
            if (!title || !placeholder)
                return;
            title.style.maxWidth = placeholder.clientWidth - 20 + "px";
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: true, 
                // need to render our text here so height of parent is set even if text is positioned absolute
                className: classes.mobileTitlePlaceholder, zeroMinWidth: true, ref: refPlaceholder, children: _jsx(PageHeaderTypography, { noWrap: true, disableFormDialogLogic: isMobileActivityDialog, component: "div", children: title }) }), _jsx(Grid, { item: true, 
                // always center text based on parent
                className: classes.mobileTitle, ref: refTitle, children: _jsx(PageHeaderTypography, { noWrap: true, disableFormDialogLogic: isMobileActivityDialog, component: "div", children: title }) })] }));
};
export default React.memo(BasicPageLayoutMobileTitle);
