import { VALID_SUBDOMAIN_PREFIXES } from "../constants";
import isSubdomain from "./isSubdomain";
const getSubdomain = (wantedSubdomain, hostname = window.location.hostname) => {
    let currentSubdomain = VALID_SUBDOMAIN_PREFIXES.find((sub) => isSubdomain(sub, hostname));
    if (hostname === "app.samedis.care")
        currentSubdomain = "app";
    if (!wantedSubdomain) {
        if (currentSubdomain) {
            // check if we're on XXX.samedis.care
            const isProdCloud = hostname.split(".").length === 3;
            return hostname.replace(currentSubdomain + ".", isProdCloud ? "app." : "");
        }
        return hostname;
    }
    if (currentSubdomain) {
        return hostname.replace(currentSubdomain + ".", wantedSubdomain + ".");
    }
    return wantedSubdomain + "." + hostname;
};
export default getSubdomain;
