import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { useTenantContext } from "./TenantContext";
import { useIsGlobalMode } from "./SamedisPortal";
import { useTenantRoutesContext, } from "./TenantRoutesProvider";
import { useRoutedMenuLogic, } from "components-care/dist/non-standalone/Portal/Menu";
import { MenuContext } from "components-care/dist/standalone/Portal/Menu/MenuItem";
import SamedisMenuGroup from "./SamedisMenuGroup";
import { useMediaQuery, useTheme } from "@mui/material";
const convTenantRoute = (tenantId, entry, closeMenu) => ({
    ...entry,
    onClick: entry.onClick
        ? (evt) => {
            if (entry.onClick)
                entry.onClick(evt);
            closeMenu();
        }
        : entry.children?.length
            ? undefined
            : closeMenu,
    children: entry.children?.map((entry) => convTenantRoute(tenantId, entry, closeMenu)),
    route: entry.route
        ? tenantId
            ? `/tenant/${tenantId}${entry.route}`
            : entry.route
        : undefined,
});
const SamedisMenu = (props) => {
    const { mobile, mobileAnchorEl, closeMenu } = props;
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("sm"));
    const tenantRoutes = useTenantRoutesContext();
    const [tenantId] = useTenantContext();
    const globalMode = useIsGlobalMode();
    const menuRawDefinition = useMemo(() => tenantRoutes.map((entry) => convTenantRoute(tenantId, entry, closeMenu)), [closeMenu, tenantId, tenantRoutes]);
    const { definition: menuDefinition, controlledState } = useRoutedMenuLogic({
        definition: menuRawDefinition,
    });
    return tenantId || globalMode ? (_jsx(MenuContext.Provider, { value: controlledState, children: _jsx(SamedisMenuGroup, { vertical: mobile, anchorEl: mobileAnchorEl, includeTenantHeader: !globalMode && isXs, itemDefs: menuDefinition, onClose: closeMenu, menuItemId: null }) })) : (_jsx(_Fragment, {}));
};
export default React.memo(SamedisMenu);
