import SamedisApiClient from "../../components-care/connectors/SamedisApiClient";
import { NetworkError } from "components-care";
import ccI18n from "components-care/dist/i18n";
import OfflineDatabase from "./OfflineDatabase";
class OfflineController {
    name;
    // example to register handler: this.routes["GET"]["/api/v4/tenants/:tenant_id/inventories"] = handler;
    routes = {
        GET: {},
        POST: {},
        PUT: {},
        DELETE: {},
    };
    constructor(name) {
        this.name = name;
    }
    getDB() {
        return OfflineDatabase.database;
    }
    getCache() {
        return caches.open("offline-db_" + this.name);
    }
    rewriteImages(img) {
        // for offline use we only cache small images
        return (img && {
            small: img.small,
            medium: img.small,
            large: img.small,
        });
    }
    async cacheImages(img) {
        if (!img)
            return;
        return this.cacheImage(img.small);
    }
    async cacheImage(img) {
        if (!img)
            return;
        const cache = await this.getCache();
        const cacheURL = new URL(img);
        cacheURL.search = "";
        // already cached?
        if (await cache.match(cacheURL))
            return;
        const response = await fetch(img);
        const content = await response.blob();
        const cachedResponse = new Response(content, {
            headers: response.headers,
            status: response.status,
            statusText: response.statusText,
        });
        await cache.put(cacheURL, cachedResponse);
    }
    async tryForward(request, orElse, success) {
        try {
            let resp = await this.forwardToBackend(request);
            if (success) {
                // clone the response in this case...
                const responseBody = await resp.text();
                await success(new Response(responseBody, {
                    status: resp.status,
                    statusText: resp.statusText,
                    headers: resp.headers,
                }));
                resp = new Response(responseBody, {
                    status: resp.status,
                    statusText: resp.statusText,
                    headers: resp.headers,
                });
            }
            return resp;
        }
        catch (e) {
            if (e instanceof Error && e.name === "NetworkError") {
                return orElse(e);
            }
            throw e;
        }
    }
    async forwardToBackend(request) {
        const { rawUrl, body: rawBody, headers, method } = request;
        const body = SamedisApiClient.convertBody(rawBody, headers);
        try {
            return await fetch(rawUrl, {
                body,
                headers,
                method,
            });
        }
        catch (e) {
            // Network error
            console.error("Failed fetch", e);
            throw new NetworkError(ccI18n.t("backend-integration.connector.json-api-client.network-error"));
        }
    }
    makeOfflineResponse(body, status = 200) {
        return new Response(JSON.stringify(body), {
            status,
            headers: { "Content-Type": "application/json" },
        });
    }
    /**
     * Handles intercepted requests
     * @param method The request method
     * @param url The request URL
     * @param headers The request headers
     * @param body The request body
     * @returns The request response
     */
    onRequest(method, url, headers, body) {
        if (!(method in this.routes))
            return;
        const possibleRoutes = this.routes[method];
        // match pathname and extract route params
        let params = {};
        let handler = null;
        const pathSplit = url.pathname.split("/");
        for (const route in possibleRoutes) {
            const routeSplit = route.split("/");
            if (routeSplit.length !== pathSplit.length)
                continue;
            let match = true;
            for (let i = 0; i < routeSplit.length; ++i) {
                const param = routeSplit[i].startsWith(":")
                    ? routeSplit[i].substring(1)
                    : false;
                if (param) {
                    params[param] = pathSplit[i];
                }
                else if (pathSplit[i] !== routeSplit[i]) {
                    match = false;
                    break;
                }
            }
            if (!match) {
                // reset params
                params = {};
            }
            else {
                handler = possibleRoutes[route];
                break;
            }
        }
        if (!handler)
            return;
        // extract query URL params
        params = { ...Object.fromEntries(url.searchParams.entries()), ...params };
        // call handler
        const response = handler({
            method: method,
            rawUrl: url,
            headers,
            params,
            body,
        });
        if (!response)
            throw new Error("Handler did not return a response"); // forgot to return?
        return response;
    }
}
export default OfflineController;
