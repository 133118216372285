import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { MaintenanceType, useMaintenanceInfo, } from "../../utils/MaintenanceMode";
import MaintenancePageEntry from "./MaintenancePageEntry";
import { useTranslation } from "react-i18next";
const MaintenancePage = (props) => {
    const { dialog } = props;
    const { t } = useTranslation("common");
    const maintenanceInfo = useMaintenanceInfo();
    return (_jsx(Box, { p: dialog ? 0 : 2, children: _jsxs(Grid, { container: true, spacing: 0, children: [maintenanceInfo.current && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h1", children: t("maintenance.explainer.header") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "body1", children: t("maintenance.explainer." + maintenanceInfo.current.type) }) }), maintenanceInfo.current.type !== MaintenanceType.Off && (_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h2", children: t("maintenance.explainer.footer") }) })), _jsx(MaintenancePageEntry, { entry: maintenanceInfo.current, type: "current" }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Box, { pt: 2 }) })] })), maintenanceInfo.planned.length > 0 && (_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h2", children: t("maintenance.planned-header") }) })), maintenanceInfo.planned.map((entry, i) => (_jsxs(_Fragment, { children: [i !== 0 && (_jsx(Grid, { item: true, xs: 12, children: _jsx(Box, { py: 1, children: _jsx(Divider, {}) }) })), _jsx(MaintenancePageEntry, { entry: entry, type: "planned" })] })))] }) }));
};
export default React.memo(MaintenancePage);
