import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { Grid } from "@mui/material";
import { selectorLocalLoadHandler, SingleSelect, } from "components-care";
import { makeStyles } from "tss-react/mui";
import { SearchAndBrowseNoBackgroundContext } from "./index";
const useStyles = makeStyles()((theme) => ({
    notchedOutline: {
        borderColor: theme.palette.divider,
    },
}));
const useNoBgLabelStyles = makeStyles()({
    label: {
        color: "#FFF",
    },
});
const FilterEntrySwitch = (props) => {
    const { name, selected, setSelected, options, title } = props;
    const { classes } = useStyles();
    const noBackground = useContext(SearchAndBrowseNoBackgroundContext);
    const { classes: noBackgroundClasses } = useNoBgLabelStyles();
    const handleSelectionChange = useCallback((selected) => {
        setSelected(name, selected ? selected.value : "");
    }, [setSelected, name]);
    return (_jsx(Grid, { item: true, children: _jsx(SingleSelect, { label: title, onLoad: selectorLocalLoadHandler(options), selected: options.find((opt) => opt.value === selected) ?? null, onSelect: handleSelectionChange, disableClearable: true, disableSearch: true, classes: noBackground ? noBackgroundClasses : undefined, textFieldInputClasses: classes }) }));
};
export default React.memo(FilterEntrySwitch);
