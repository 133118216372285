import { CachedServerStorageProvider, } from "components-care";
import SamedisApiClient from "./connectors/SamedisApiClient";
export class BackendStorageProvider extends CachedServerStorageProvider {
    getAll() {
        return SamedisApiClient.get("/api/v4/user/settings", null);
    }
    clearFromServer() {
        return SamedisApiClient.delete("/api/v4/user/settings", null);
    }
    async getItemFromServer(key) {
        try {
            const data = await SamedisApiClient.get(`/api/v4/user/settings/${encodeURIComponent(key)}`, null);
            return data.data.attributes.value;
        }
        catch (error) {
            if (error instanceof Error &&
                error.name === "BackendError" &&
                error.code === "record_not_found_error") {
                return null;
            }
            throw error;
        }
    }
    async setItemOnServer(key, value) {
        if (value != null) {
            try {
                await SamedisApiClient.put(`/api/v4/user/settings/${encodeURIComponent(key)}`, null, { data: { value } });
            }
            catch (error) {
                if (error instanceof Error &&
                    error.name === "BackendError" &&
                    error.code === "record_not_found_error") {
                    await SamedisApiClient.post("/api/v4/user/settings", null, {
                        data: { key, value },
                    });
                }
                else {
                    throw error;
                }
            }
        }
        else {
            try {
                await SamedisApiClient.delete(`/api/v4/user/settings/${encodeURIComponent(key)}`, null);
            }
            catch (error) {
                if (error instanceof Error &&
                    error.name === "BackendError" &&
                    error.code === "record_not_found_error") {
                    return; // ignore
                }
                else {
                    throw error;
                }
            }
        }
    }
}
const BackendStorageProviderInstance = new BackendStorageProvider();
export default BackendStorageProviderInstance;
