import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import TenantList from "./components/FacilityList";
import { useAuthProviderContext, useAuthProviderReset, } from "../components/AuthProvider";
const TenantSelection = () => {
    const authCtx = useAuthProviderContext();
    const resetAuthCtx = useAuthProviderReset();
    useEffect(() => {
        // if the last time auth context was refreshed was less then 10 sec ago, do nothing
        if (authCtx.updated_at > new Date(new Date().getTime() - 10000))
            return;
        // otherwise refresh the auth context
        resetAuthCtx();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return _jsx(TenantList, {});
};
export default React.memo(TenantSelection);
