import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PolicyViewer, { PRIVACY_DOCUMENT, TOS_DOCUMENT, } from "./PolicyViewer";
import { useDialogContext } from "components-care";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()({
    link: {
        cursor: "pointer",
        textDecoration: "underline",
    },
});
const PolicyLinks = () => {
    const { t } = useTranslation("tenant-select");
    const [pushDialog] = useDialogContext();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const { classes } = useStyles();
    const openDocument = useCallback((doc) => {
        pushDialog(_jsx(PolicyViewer, { document: doc }));
    }, [pushDialog]);
    const openTosDocument = useCallback(() => openDocument(TOS_DOCUMENT), [openDocument]);
    const openPrivacyDocument = useCallback(() => openDocument(PRIVACY_DOCUMENT), [openDocument]);
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(Typography, { color: "background.paper", className: classes.link, onClick: openTosDocument, children: t("link.tos") }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(Typography, { align: isXs ? "left" : "right", color: "background.paper", className: classes.link, onClick: openPrivacyDocument, children: t("link.privacy") }) })] }));
};
export default React.memo(PolicyLinks);
