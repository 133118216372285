import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useState, } from "react";
import Cookies from "js-cookie";
import SamedisApiClient from "../../components-care/connectors/SamedisApiClient";
import AuthMode from "components-care/dist/backend-integration/Connector/AuthMode";
import { Loader, showInfoDialog, useNavigate, useLocation, useDialogContext, } from "components-care";
import * as Sentry from "@sentry/react";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { IdentityManagementAppName, IdentityManagementHost, } from "../../constants";
export const ANONYMOUS_USER_ID = "anonymous";
const AuthProviderContext = React.createContext(undefined);
export const useAuthProviderContext = () => {
    const ctx = useContext(AuthProviderContext);
    if (!ctx)
        throw new Error("Auth Provider context not set");
    return ctx;
};
const AuthProviderResetContext = React.createContext(undefined);
export const useAuthProviderReset = () => {
    const ctx = useContext(AuthProviderResetContext);
    if (!ctx)
        throw new Error("Auth Provider context is not set");
    return ctx;
};
const AuthProviderUpdateContext = React.createContext(undefined);
export const useAuthProviderContextUpdate = () => {
    const ctx = useContext(AuthProviderUpdateContext);
    if (!ctx)
        throw new Error("Auth Provider context is not set");
    return ctx;
};
//
// Authentication Utils
//
class AuthError extends Error {
    constructor(msg) {
        super(msg);
        this.name = "AuthError";
    }
}
const parseUrlParams = () => new URLSearchParams(window.location.hash.replace("#", "?"));
export const getSession = () => parseUrlParams().get("token") ||
    sessionStorage.getItem("token") ||
    Cookies.get("token") ||
    localStorage.getItem("token") ||
    null;
export const getSessionExpire = () => {
    const expireStr = parseUrlParams().get("token_expire") ||
        sessionStorage.getItem("token_expire") ||
        Cookies.get("token_expire") ||
        localStorage.getItem("token_expire");
    if (!expireStr)
        return null;
    return new Date(/^[0-9]+$/.test(expireStr) ? parseInt(expireStr) : expireStr);
};
export const getSessionRefreshToken = () => parseUrlParams().get("refresh_token") ||
    sessionStorage.getItem("refresh_token") ||
    Cookies.get("refresh_token") ||
    localStorage.getItem("refresh_token") ||
    null;
export const destroySession = async () => {
    if (isSessionValid()) {
        // fire and forget
        try {
            await SamedisApiClient.delete("/api/v4/user/session", {
                token_type_hint: getSessionRefreshToken()
                    ? "access_token"
                    : undefined,
            }, AuthMode.Try);
        }
        catch (_e) {
            // ignored
        }
    }
    ["token", "token_expire", "refresh_token"].forEach((key) => {
        Cookies.remove(key);
        delete localStorage[key];
        delete sessionStorage[key];
    });
};
export const redirectToLogin = (includeRedirectURl = true) => {
    if (!IdentityManagementHost || !IdentityManagementAppName) {
        throw new Error("Identity Service Variables not set!");
    }
    const redirectUrl = includeRedirectURl
        ? window.location.href
        : window.location.origin;
    window.location.href = `${localStorage.IM_HOST_OVERRIDE ?? IdentityManagementHost}/login/${encodeURI(IdentityManagementAppName)}?redirect_host=${encodeURIComponent(redirectUrl)}`;
};
export const openProfile = () => {
    if (!IdentityManagementHost || !IdentityManagementAppName) {
        throw new Error("Identity Service Variables not set!");
    }
    const session = getSession();
    if (!isSessionValid() || !session) {
        throw new Error("Not authenticated");
    }
    window.open(`${localStorage.IM_HOST_OVERRIDE ?? IdentityManagementHost}/my-profile?app=${encodeURIComponent(IdentityManagementAppName)}#token=${encodeURIComponent(session)}&token_expire=${encodeURIComponent(getSessionExpire()?.toISOString() ?? Date.now().toString())}`, "_blank");
};
export const isSessionValid = () => {
    const session = getSession();
    const sessionExpire = getSessionExpire();
    return !!(session && sessionExpire && sessionExpire > new Date());
};
let temporarilyDisableAutoRedirectToLogin = false;
export const handleAuth = async () => {
    const session = getSession();
    if (!isSessionValid()) {
        await destroySession();
        if (!temporarilyDisableAutoRedirectToLogin) {
            redirectToLogin();
        }
        throw new AuthError("No authentication set");
    }
    return "Bearer " + session;
};
/**
 * Auth Provider for Ident.Services
 * @constructor
 */
const AuthProvider = (props) => {
    const { children, optional } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const [pushDialog] = useDialogContext();
    const [ctx, setCtx] = useState(undefined);
    const resetAuthContext = useCallback(() => {
        setCtx(undefined);
    }, []);
    // handle /authenticated and enforce authentication
    useEffect(() => {
        if (ctx)
            return;
        (async () => {
            // handle authentication page
            if (location.pathname === "/authenticated") {
                // save auth params
                const search = parseUrlParams();
                navigate({
                    pathname: location.pathname,
                    search: location.search,
                    hash: "",
                }, { replace: true });
                const rememberMe = search.get("remember_me") === "true";
                const tokens = Object.fromEntries([
                    "token",
                    "refresh_token",
                    "token_expire",
                    "invite_token",
                    "slim",
                    "lang",
                ].map((key) => [key, search.get(key)]));
                Object.entries(tokens)
                    .filter(([k]) => ["token", "refresh_token", "token_expire"].includes(k))
                    .forEach(([k, v]) => {
                    if (!v)
                        return;
                    sessionStorage.setItem(k, v);
                    Cookies.set(k, v, { sameSite: "strict", secure: true });
                    if (rememberMe)
                        localStorage.setItem(k, v);
                });
                sessionStorage.setItem("slim", tokens.slim ?? "false");
                if (tokens.lang)
                    i18n.changeLanguage(tokens.lang).catch(console.error);
                // accept invite
                const { invite_token } = tokens;
                if (invite_token) {
                    let tryAgain = true;
                    while (tryAgain) {
                        try {
                            const invite = await SamedisApiClient.put(`/api/v4/user/invitations/${encodeURI(invite_token)}`, null, {});
                            localStorage.setItem("tenant", invite.data.attributes.tenant_id); // set default tenant after redirect
                            tryAgain = false;
                        }
                        catch (e) {
                            if (e instanceof Error &&
                                e.name === "BackendError" &&
                                e.code === "record_not_found_error") {
                                // special handling record not found error (invalid or expired invite)
                                await showInfoDialog(pushDialog, {
                                    title: t("auth_provider.fail_accept_invite.title"),
                                    message: t("auth_provider.fail_accept_invite.record_not_found_message"),
                                    buttons: [
                                        {
                                            text: t("auth_provider.fail_accept_invite.okay"),
                                            autoFocus: true,
                                            onClick: () => {
                                                tryAgain = false;
                                            },
                                        },
                                    ],
                                });
                            }
                            else {
                                Sentry.captureException(e);
                                await showInfoDialog(pushDialog, {
                                    title: t("auth_provider.fail_accept_invite.title"),
                                    message: e.message,
                                    buttons: [
                                        {
                                            text: t("auth_provider.fail_accept_invite.retry"),
                                            autoFocus: true,
                                        },
                                        {
                                            text: t("auth_provider.fail_accept_invite.ignore"),
                                            onClick: () => {
                                                tryAgain = false;
                                            },
                                        },
                                    ],
                                });
                            }
                        }
                    }
                }
                navigate(search.get("redirect_path") ?? "/", { replace: true });
            }
            // check for session
            if (!isSessionValid()) {
                if (optional) {
                    // set anonymous user
                    setCtx({
                        candos: [],
                        current_user: {
                            id: ANONYMOUS_USER_ID,
                            company: null,
                            department: null,
                            email: "anonymous@samedis.care",
                            gender: 0,
                            first_name: "Anonymous",
                            last_name: "User",
                            image: null,
                            user_avatar: null,
                            job_title: null,
                            locale: null,
                            mobile: null,
                            personnel_number: null,
                            short: null,
                            recovered_account: false,
                            tenants: [],
                            username: "anonymous@samedis.care",
                        },
                        updated_at: new Date(),
                    });
                }
                else {
                    await destroySession();
                    redirectToLogin();
                }
            }
            else {
                // copy cookies to sessionStorage for faster access
                ["token", "token_expire", "refresh_token"]
                    .map((key) => [key, localStorage[key] ?? Cookies.get(key)])
                    .filter(([, value]) => value)
                    .forEach(([key, value]) => sessionStorage.setItem(key, value));
                // get user info
                try {
                    temporarilyDisableAutoRedirectToLogin = !!props.optional;
                    const userInfo = await SamedisApiClient.get("/api/v4/get_current_user", null);
                    setCtx({
                        ...userInfo.data,
                        updated_at: new Date(),
                    });
                }
                catch (e) {
                    console.error(e);
                    if (!props.optional) {
                        await showInfoDialog(pushDialog, {
                            title: t("auth_provider.fail_fetch_profile.title"),
                            message: e.message,
                            buttons: [
                                {
                                    text: t("auth_provider.fail_fetch_profile.retry"),
                                    autoFocus: true,
                                },
                            ],
                        });
                        window.location.reload();
                    }
                }
                finally {
                    temporarilyDisableAutoRedirectToLogin = false;
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctx]);
    // set current user for sentry
    useEffect(() => {
        if (!ctx) {
            // not authenticated
            Sentry.setUser(null);
        }
        else {
            Sentry.setUser({
                id: ctx.current_user.id,
            });
        }
    }, [ctx]);
    return ctx ? (_jsx(AuthProviderUpdateContext.Provider, { value: setCtx, children: _jsx(AuthProviderResetContext.Provider, { value: resetAuthContext, children: _jsx(AuthProviderContext.Provider, { value: ctx, children: children }) }) })) : (_jsx(Loader, {}));
};
export default React.memo(AuthProvider);
