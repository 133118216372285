import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ExpandMore } from "@mui/icons-material";
const useStyles = makeStyles()((theme) => ({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&.Mui-expanded": {
            margin: "auto",
        },
        height: "100%",
        padding: 1,
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        "& div.MuiCollapse-entered": { flexGrow: 1 },
        "& div.MuiCollapse-wrapper": { height: "100%" },
        "& div.MuiCollapse-wrapperInner": {
            height: "100%",
            "& div[role='region']": { height: "100%" },
        },
    },
    summary: {
        alignItems: "start",
        background: "white",
        minHeight: 36,
        padding: "0 8px 0 15px",
        borderRadius: theme.shape.borderRadius,
        "&.Mui-expanded": {
            minHeight: 36,
            borderBottom: "1px solid rgba(0, 0, 0, .125)",
            borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
        },
    },
    content: {
        margin: "6px 0px",
        "&.Mui-expanded": { margin: "6px 0px" },
        fontFamily: "Roboto Condensed",
    },
    expandIcon: {
        marginTop: 6,
    },
    label: {
        marginTop: 2,
        width: "calc(100% - " + theme.spacing(2) + ")",
    },
    details: {
        padding: theme.spacing(1),
        height: "100%",
    },
}));
const AccordionControl = (props) => {
    const { label, children, expanded, onChange, className } = props;
    const { classes } = useStyles();
    return (_jsxs(Accordion, { expanded: expanded, elevation: 0, className: className, classes: { root: classes.root }, onChange: (_e, expanded) => onChange(expanded), children: [_jsx(AccordionSummary, { classes: {
                    root: classes.summary,
                    content: classes.content,
                    expandIconWrapper: classes.expandIcon,
                }, expandIcon: _jsx(ExpandMore, {}), children: _jsx("div", { className: classes.label, children: label }) }), _jsx(AccordionDetails, { classes: { root: classes.details }, children: children })] }));
};
export default React.memo(AccordionControl);
