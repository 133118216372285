import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { Grid, Typography, InputAdornment, Link as MuiLink, } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { debounce, TextFieldWithHelp, getStringLabel, } from "components-care";
import AccordionControl from "../AccordionControl";
import { Search } from "@mui/icons-material";
import * as Sentry from "@sentry/react";
import FilterEntryMultiSelectOption, { FilterEntryMultiSelectOptionContext, } from "./FilterEntryMultiSelectOption";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles()({
    root: {
        height: "100%",
    },
    accordionExpanded: {
        minHeight: 300,
    },
    scrollContainer: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    scrollWrapper: {
        position: "absolute",
        height: "100%",
        width: "100%",
    },
});
const FilterEntryMultiSelect = (props) => {
    const { name, expanded, setExpanded, selected, setSelected, loadOptions, loadOption, title, } = props;
    const { classes } = useStyles();
    const { t } = useTranslation("global-device-catalog");
    const infiniteLoader = useRef(null);
    const [resetPending, setResetPending] = useState(false);
    const infiniteLoaderReset = useMemo(() => debounce(() => {
        setOptions([]);
        setTotal(null);
        setResetPending(true);
    }, 500), []);
    useEffect(() => {
        if (!resetPending)
            return;
        infiniteLoader.current?.resetloadMoreItemsCache(true);
        setResetPending(false);
    }, [resetPending]);
    const [options, setOptions] = useState([]);
    const [optionsCache, setOptionsCache] = useState({});
    const [forceFetchCache, setForceFetchCache] = useState(false);
    const [search, setSearch] = useState("");
    const [total, setTotal] = useState(null);
    const canLoadMore = total == null ? true : total > options.length;
    const [lastError, setLastError] = useState(null);
    const handleExpandedChange = useCallback((expanded) => {
        setExpanded(name, expanded);
    }, [name, setExpanded]);
    const resetFilters = useCallback((evt) => {
        evt.stopPropagation();
        setSearch("");
        setSelected(name, (prev) => (prev.length === 0 ? prev : []));
    }, [name, setSelected]);
    const hasFilters = search.length > 0 || selected.length > 0;
    const handleSearchChange = useCallback((evt) => {
        setSearch(evt.target.value);
    }, []);
    const initialLoad = useRef(true);
    // react 18 remounting stuff
    useEffect(() => {
        return () => {
            initialLoad.current = true;
        };
    }, []);
    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        infiniteLoaderReset();
    }, [search, infiniteLoaderReset]);
    useEffect(() => {
        setForceFetchCache(false);
        const missingData = selected.filter((sel) => !(sel in optionsCache));
        // mark as loading
        setOptionsCache((prev) => {
            const newCache = { ...prev };
            missingData.forEach((value) => {
                newCache[value] = null;
            });
            return newCache;
        });
        (async () => {
            const result = await Promise.all(missingData.map(async (value) => {
                try {
                    const result = await loadOption(value);
                    return [value, result];
                }
                catch (e) {
                    console.error(e);
                    Sentry.captureException(e);
                    return [value, e];
                }
            }));
            setOptionsCache((prev) => {
                const newCache = { ...prev };
                result.forEach(([value, result]) => {
                    newCache[value] = result;
                });
                return newCache;
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, forceFetchCache]);
    const loadingOffset = useRef([]);
    const loadItems = useCallback(async (start, end) => {
        // prevent double calls to load the same range (prevents duplicates)
        if (loadingOffset.current.includes(start))
            return;
        loadingOffset.current.push(start);
        try {
            const { data: moreOptions, total } = await loadOptions(search, start, end - start + 1);
            if (moreOptions.length > end - start + 1) {
                throw new Error(`too many options ${moreOptions.length} > ${end - start + 1}`);
            }
            setOptionsCache((prev) => {
                const newCache = { ...prev };
                moreOptions.forEach((opt) => (newCache[opt.value] = opt));
                return newCache;
            });
            setOptions((opts) => [
                ...opts,
                ...moreOptions.filter(
                // ensure no duplicates (failsafe)
                (opt) => !opts.find((present) => present.value === opt.value)),
            ]);
            setTotal(total);
            setLastError(null);
        }
        catch (e) {
            console.error(e);
            Sentry.captureException(e);
            setLastError(e);
        }
        finally {
            // remove offset from loading
            loadingOffset.current.splice(loadingOffset.current.indexOf(start), 1);
        }
    }, [loadOptions, search]);
    const handleSelectedChange = useCallback((value, checked) => {
        setSelected(name, (selected) => checked
            ? [...selected, value]
            : [...selected].filter((s) => s !== value));
    }, [name, setSelected]);
    const handleRetry = useCallback((value) => {
        setOptionsCache((prev) => ({
            ...prev,
            [value]: null,
        }));
        setForceFetchCache(true);
    }, []);
    const recordContext = useMemo(() => ({
        onRetry: handleRetry,
        onSelectionChanged: handleSelectedChange,
        data: selected
            .map((val) => ({
            value: val,
            opt: optionsCache[val],
            selected: true,
        }))
            .concat(options
            .filter((opt) => !opt || !selected.includes(opt.value))
            .map((opt) => ({
            value: opt.value,
            opt,
            selected: false,
        })))
            .concat(lastError ? [lastError] : []),
    }), [
        handleRetry,
        handleSelectedChange,
        options,
        optionsCache,
        selected,
        lastError,
    ]);
    return (_jsx(Grid, { item: true, xs: expanded, children: _jsx(AccordionControl, { expanded: expanded, onChange: handleExpandedChange, className: expanded ? classes.accordionExpanded : undefined, label: _jsxs(Grid, { container: true, justifyContent: "space-between", children: [_jsx(Grid, { item: true, children: _jsx(Typography, { children: title }) }), hasFilters && (_jsx(Grid, { item: true, children: _jsx(MuiLink, { href: "#", onClick: resetFilters, children: _jsx(Typography, { children: t("filters.clear") }) }) }))] }), children: _jsxs(Grid, { container: true, spacing: 2, direction: "column", className: classes.root, wrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx(TextFieldWithHelp, { fullWidth: true, value: search, onChange: handleSearchChange, variant: "outlined", InputProps: {
                                startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(Search, {}) })),
                            } }) }), _jsx(Grid, { item: true, xs: true, children: _jsx("div", { className: classes.scrollContainer, children: _jsx("div", { className: classes.scrollWrapper, children: !canLoadMore && options.length === 0 ? (_jsx(Typography, { children: t(search ? "filters.no-data-filter" : "filters.no-data") })) : (_jsx(FilterEntryMultiSelectOptionContext.Provider, { value: recordContext, children: _jsx(AutoSizer, { children: ({ width, height }) => (_jsx(InfiniteLoader, { ref: infiniteLoader, isItemLoaded: (index) => index < options.length && !!options[index], itemCount: recordContext.data.length + (canLoadMore ? 25 : 0), loadMoreItems: loadItems, minimumBatchSize: 25, children: ({ onItemsRendered, ref }) => (_jsx(FixedSizeList, { itemSize: 28, height: height, width: width, onItemsRendered: onItemsRendered, ref: ref, itemCount: recordContext.data.length +
                                                    (canLoadMore ? 25 : 0), children: FilterEntryMultiSelectOption })) })) }) })) }) }) })] }) }) }));
};
const PER_PAGE = 25;
export const multiSelectFilterEntryFromModel = (model, sort, mapFn) => ({
    loadOptions: async (query, offset, maxRows) => {
        const [data, meta] = await model.index2({
            offset: offset,
            rows: Math.min(PER_PAGE, maxRows),
            sort,
            quickFilter: query,
        });
        return {
            data: data.map(mapFn).map((opt) => ({
                ...opt,
                label: getStringLabel(opt),
                tooltip: ("tooltip" in opt ? opt.tooltip : null) ?? getStringLabel(opt),
            })),
            total: meta.filteredRows ?? meta.totalRows,
        };
    },
    loadOption: async (id) => {
        const [data] = await model.getCached(id);
        const converted = mapFn(data);
        return {
            ...converted,
            label: getStringLabel(converted),
            tooltip: ("tooltip" in converted ? converted.tooltip : null) ??
                getStringLabel(converted),
        };
    },
});
export const multiSelectFilterEntryFromEnum = (enumValues) => ({
    loadOptions: (query, offset, maxRows) => {
        query = query.toLowerCase();
        const data = enumValues.filter((e) => e.getLabel().toLowerCase().includes(query));
        return {
            data: data.slice(offset, offset + maxRows).map((option) => ({
                value: option.value,
                label: option.getLabel(),
                tooltip: option.getLabel(),
            })),
            total: data.length,
        };
    },
    loadOption: (id) => {
        const enumValue = enumValues.find((entry) => entry.value === id);
        if (!enumValue)
            throw new Error("enum value not found");
        return {
            value: enumValue.value,
            label: enumValue.getLabel(),
            tooltip: enumValue.getLabel(),
        };
    },
});
export default React.memo(FilterEntryMultiSelect);
