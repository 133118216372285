import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { getLocalizedReason, } from "../../utils/MaintenanceMode";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { marked } from "marked";
import useAsyncMemo from "components-care/dist/utils/useAsyncMemo";
const MaintenancePageEntry = (props) => {
    const { i18n, t } = useTranslation("common");
    const { entry, type } = props;
    const infoMarkdown = useAsyncMemo(() => entry.reason_long ? marked(getLocalizedReason(entry.reason_long)) : null, [entry.reason_long]);
    return (_jsx(Grid, { item: true, xs: 12, children: _jsxs(Grid, { container: true, spacing: 0, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "body1", children: getLocalizedReason(entry.reason) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "body1", children: t("maintenance.entry.start", {
                            TIMESTAMP: entry.start.toLocaleString(i18n.language),
                        }) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "body1", children: t("maintenance.entry.end", {
                            TIMESTAMP: entry.end
                                ? entry.end.toLocaleString(i18n.language)
                                : t("maintenance.entry.end-unknown"),
                        }) }) }), type !== "current" && (_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "body1", children: t("maintenance.explainer.planned." + entry.type) }) })), infoMarkdown && (_jsx(Grid, { item: true, xs: 12, children: _jsx("div", { dangerouslySetInnerHTML: { __html: infoMarkdown } }) }))] }) }));
};
export default React.memo(MaintenancePageEntry);
