import { useMutation, useQuery } from "@tanstack/react-query";
import SamedisApiClient from "../../components-care/connectors/SamedisApiClient";
import { ModelDataStore } from "components-care";
import { ReactQueryRetryHandler } from "../../constants";
/**
 * Invites for the current user
 */
export const useOpenInvites = () => {
    return useQuery({
        queryKey: ["v4/user/invitations"],
        queryFn: async () => {
            const data = (await SamedisApiClient.get("/api/v4/user/invitations", null)).data;
            return data.map((record) => record.attributes);
        },
        retry: ReactQueryRetryHandler,
    });
};
export const useAcceptInvite = () => {
    return useMutation({
        mutationKey: ["PUT_v4/user/invitations"],
        mutationFn: (token) => {
            return SamedisApiClient.put(`/api/v4/user/invitations/${encodeURIComponent(token)}`, null, {});
        },
        onSuccess: () => {
            return ModelDataStore.invalidateQueries({
                queryKey: ["v4/user/invitations"],
                exact: true,
            });
        },
    });
};
export const useRejectInvite = () => {
    return useMutation({
        mutationKey: ["DELETE_v4/user/invitations"],
        mutationFn: (token) => {
            return SamedisApiClient.delete(`/api/v4/user/invitations/${encodeURIComponent(token)}`, null);
        },
        onSuccess: () => {
            return ModelDataStore.invalidateQueries({
                queryKey: ["v4/user/invitations"],
                exact: true,
            });
        },
    });
};
