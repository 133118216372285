import { green } from "@mui/material/colors";
import { FORM_DIALOG_PADDING, MultiSelectorDefaultSelectedSort, } from "./constants";
import SamedisFormPageLayout from "./components-care/SamedisFormPageLayout";
import { SamedisMobileFormButtonsWrapperClassName, SamedisMobileFormButtonsWrapperMobileClassName, } from "./components-care/SamedisMobileFormButtonsPortal";
import SamedisFullFormDialog from "./components-care/SamedisFullFormDialog";
export const customColors = {
    white: "rgb(255, 255, 255)",
    grey: "rgb(127, 127, 127)",
    lightGray: "rgb(215, 215, 215)",
    lightGreyBackground: "rgb(247, 247, 247)",
    lighterBlue: "rgb(218, 236, 248)",
    lightBlue: "rgb(182, 216, 241)",
    turquoise: "#00AFC3",
    darkBlue: "#002D50",
    greyLabel: "#595655",
};
const customPalette = {
    primary: {
        main: "rgb(8, 105, 179)",
        light: "rgb(57, 135, 194)",
    },
    secondary: {
        main: "rgb(185, 215, 240)",
    },
    action: {
        hover: "rgba(0,0,0, .2)",
    },
    error: {
        main: "rgb(230, 45, 75)",
    },
    warning: {
        main: "#ffcd00",
    },
    success: {
        main: green[500],
    },
    background: {
        default: "#FFF",
        paper: "#FFF",
    },
    divider: "#E5EAF2",
};
export const backgroundGradient = `linear-gradient(270deg, ${customColors.turquoise} 0%, ${customPalette.primary.main} 100%)`;
export const blurFilter = "blur(30px) opacity(0.7)";
const imagePreviewDialogStyles = ({ theme }) => ({
    "& > .MuiDialog-container": {
        padding: theme.spacing(2),
        [theme.breakpoints.only("xs")]: {
            padding: 0,
        },
    },
    "& > .MuiDialog-container > .MuiPaper-root": {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: blurFilter,
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.only("xs")]: {
            borderRadius: 0,
        },
    },
});
const filterActiveColor = customPalette.warning.main;
const BREAKPOINTS = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};
const fontSize = {
    base: 13,
};
const typography = {
    h1: {
        fontSize: "2rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    h2: {
        fontSize: "1rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    h3: {
        fontSize: "0.8125rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    h4: {
        fontSize: "0.75rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    h5: {
        fontSize: "0.5625rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    h6: {
        fontSize: "0.4375rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    subtitle1: {
        fontWeight: 500,
        fontSize: "1rem",
        letterSpacing: -0.21,
        fontFamily: '"Roboto", sans-serif',
        lineHeight: 1.43,
    },
    subtitle2: {
        fontWeight: 500,
        fontSize: "0.8125rem",
        letterSpacing: -0.17,
        fontFamily: '"Roboto", sans-serif',
        lineHeight: 1.43,
    },
    body1: {
        fontWeight: 400,
        fontSize: "0.8125rem",
        letterSpacing: 0.16,
        fontFamily: '"Roboto", sans-serif',
    },
    body2: {
        fontWeight: 400,
        fontSize: "0.5625rem",
        letterSpacing: 0.11,
        fontFamily: '"Roboto", sans-serif',
    },
    caption: {
        fontWeight: 400,
        fontSize: "1rem", // this is used for labels and displayed at 75% scale most of the time, so 0.75 rem
        letterSpacing: 0.14,
        fontFamily: '"Roboto", sans-serif',
    },
    caption2: {
        fontWeight: 400,
        fontSize: "0.75rem",
        letterSpacing: 0.105,
        fontFamily: '"Roboto", sans-serif',
    },
    button: {
        textTransform: "unset",
        fontWeight: 400,
        fontSize: "0.8125rem",
        letterSpacing: 0.16,
        fontFamily: '"Roboto", sans-serif',
    },
    overline: {
        textTransform: "unset",
        fontWeight: 400,
        fontSize: "0.8125rem",
        letterSpacing: 0.16,
        fontFamily: '"Roboto", sans-serif',
    },
};
const dataGridFilterStyles = {
    "& .MuiAutocomplete-root.Mui-active": {
        borderColor: filterActiveColor,
        "& > fieldset": {
            borderColor: filterActiveColor,
        },
        "& .MuiAutocomplete-inputRoot": {
            borderColor: filterActiveColor,
            "& > fieldset": {
                borderColor: filterActiveColor,
            },
        },
    },
};
const dataGridFilterBarStyles = {
    barRoot: {
        maxWidth: "200px !important",
        "& .MuiInputLabel-root": {
            lineHeight: 1,
            fontSize: "0.75rem",
            transform: "translate(3px, -3px)",
        },
        "& .MuiAutocomplete-root": {
            padding: "0 4px",
        },
    },
};
const BORDER_RADIUS = 5;
const ccInputStyles = {
    root: {
        "& MuiInput-root": {
            paddingRight: 0,
        },
        "& MuiInput-input": {
            paddingRight: 16,
            "&::placeholder": {
                fontSize: "0.75rem",
                fontStyle: "italic",
            },
        },
        "& MuiInput-multiline": {
            paddingRight: "4px !important",
        },
    },
};
export const getTheme = () => ({
    typography: {
        fontSize: fontSize.base,
        ...typography,
    },
    breakpoints: {
        values: BREAKPOINTS,
    },
    shape: {
        borderRadius: BORDER_RADIUS,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: "0.8125rem",
                    lineHeight: 1.43,
                    letterSpacing: "0.01071em",
                },
            },
        },
        MuiTypography: {
            defaultProps: {
            /*variantMapping: {
              //caption2: // not defined, use default
            }*/
            },
            styleOverrides: {
                root: {
                    ".MuiTooltip-tooltip &": {
                        color: "#FFFFFF",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    whiteSpace: "nowrap",
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    borderRadius: BORDER_RADIUS,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    margin: "8px 8px 8px 5px !important",
                    ".components-care-data-grid-filter-bar &": {
                        margin: "2px 8px 2px 5px !important",
                    },
                    fontSize: fontSize.base,
                    "&.Mui-disabled::placeholder": {
                        opacity: 0,
                    },
                    ".SamedisInputRTL &": {
                        // trigger ellipsis on left side of string
                        textAlign: "left",
                        direction: "rtl",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: "2.5px 4px",
                },
                inputMultiline: {
                    margin: "0 !important",
                },
                notchedOutline: {
                    "& > legend": {
                        ...typography.caption2,
                    },
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: "unset",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    ...typography.subtitle1,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    ["@media (max-width: " + BREAKPOINTS.sm + "px)"]: {
                        margin: 8,
                        width: "calc(100% - 16px)",
                        maxHeight: "calc(100% - 16px)",
                    },
                },
                paperFullScreen: {
                    ["@media (max-width: " + BREAKPOINTS.sm + "px)"]: {
                        margin: 0,
                        width: "100%",
                        maxHeight: "100%",
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    ...typography.h2,
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                backdrop: {
                    // dialog background color
                    // https://github.com/Samedis-care/samedis-care-issues/issues/1652#issuecomment-2301496853
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    ...typography.body1,
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                text: {
                    ...typography.body1,
                },
            },
        },
        MuiPopover: {
            defaultProps: {
                PaperProps: {
                    elevation: 0,
                    style: { border: "1px solid " + customPalette.divider },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    ...typography.caption,
                },
            },
        },
        MuiDatePicker: {
            defaultProps: {
                views: ["year", "month", "day"],
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    '&[class*="MuiOutlinedInput-root"]': {
                        paddingLeft: 8,
                        paddingTop: 0,
                        paddingBottom: 0,
                        "& .MuiAutocomplete-input": {
                            padding: "2.5px 4px",
                        },
                    },
                    '&[class*="MuiAutocomplete-inputRoot"]': {
                        paddingRight: "10px !important",
                        flexWrap: "nowrap",
                    },
                },
                paper: {
                    margin: 0,
                },
                noOptions: {
                    padding: "2.5px 16px",
                },
                loading: {
                    padding: "2.5px 16px",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    ...typography.body1,
                    padding: 8,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    ...typography.body2,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    ".MuiTooltip-tooltip &": {
                        color: "#FFFFFF",
                    },
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    minWidth: 60,
                },
            },
        },
        // CC START
        CcSignalPortlet: {
            // keep in sync with useMyTypographyStyles
            // https://github.com/Samedis-care/samedis-care-issues/issues/1293
            styleOverrides: {
                divider: {
                    display: "none",
                },
                title: ({ theme }) => ({
                    fontFamily: "Roboto",
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: theme.palette.text.secondary,
                    textTransform: "uppercase",
                    paddingLeft: theme.spacing(2),
                    textAlign: "left",
                }),
                root: {
                    display: "flex",
                    flex: "1",
                    flexGrow: "1",
                },
                paper: ({ theme }) => ({
                    boxShadow: "none",
                    paddingTop: theme.spacing(1),
                    display: "flex",
                    flexDirection: "column",
                    flex: "1",
                }),
                innerContainer: {
                    display: "flex",
                    flex: "1",
                },
                lastUpdatedAt: ({ theme }) => ({
                    ...theme.typography.body2,
                }),
            },
        },
        CcSignalPortletItem: {
            styleOverrides: {
                itemColorInactive: {
                    color: "#FFF",
                    backgroundColor: customColors.greyLabel,
                },
                listText: {
                    "& .MuiTypography-root": {
                        // keep in sync with useMyTypographyStyles
                        // https://github.com/Samedis-care/samedis-care-issues/issues/1293
                        fontSize: "1rem",
                        fontWeight: 700,
                        color: customColors.darkBlue,
                        textTransform: "uppercase",
                    },
                },
            },
        },
        CcWeekView: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: customPalette.background.paper,
                    borderRadius: theme.shape.borderRadius,
                }),
            },
        },
        CcWeekViewDay: {
            styleOverrides: {
                paper: ({ theme }) => ({
                    "&.CcWeekViewDay-today": {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                    },
                }),
            },
        },
        CcScrollableSchedule: {
            styleOverrides: {
                today: ({ theme }) => ({
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.primary.main,
                }),
                filterSettingsBtn: ({ theme }) => ({
                    color: theme.palette.primary.main,
                }),
            },
        },
        CcScrollableScheduleDay: {
            styleOverrides: {
                root: ({ theme }) => ({
                    "&.CcScrollableScheduleDay-today": {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        paddingBottom: theme.spacing(2),
                    },
                }),
            },
        },
        CcActionButton: {
            styleOverrides: {
                button: {
                    padding: "7px 25px",
                    borderRadius: 25,
                    fontSize: "0.75rem",
                    border: "none",
                    "&.Mui-disabled": {
                        backgroundColor: "#bcbdbf",
                        border: "none",
                        boxShadow: "rgba(255, 255, 255, 0.25) 0px 0px 2px 0px",
                    },
                    "&.MuiButton-contained": {
                        borderRadius: 25,
                    },
                },
            },
        },
        CcFormButtons: {
            styleOverrides: {
                buttonWrapper: {
                    margin: "0 25px 0 0",
                    [`.${SamedisMobileFormButtonsWrapperClassName} &`]: {
                        margin: "0 16px 0 0",
                    },
                },
                root: ({ theme }) => ({
                    width: "auto",
                    border: "none",
                    borderRadius: 0,
                    padding: theme.spacing(1, 4),
                    margin: theme.spacing(-1, 0),
                    backgroundColor: "rgba(185, 215, 240, 0.35)",
                    boxShadow: "rgba(185, 215, 240, 0.35) 10px 10px 10px 6px",
                    backdropFilter: blurFilter,
                    flexWrap: "wrap",
                    [`.${SamedisMobileFormButtonsWrapperClassName} &`]: {
                        backgroundColor: "unset",
                        boxShadow: "unset",
                        backdropFilter: "unset",
                        padding: 0,
                        margin: 0,
                    },
                }),
            },
        },
        CcFormDialog: {
            defaultProps: {
                renderer: SamedisFullFormDialog,
            },
            styleOverrides: {
                content: ({ theme }) => ({
                    display: "flex",
                    flexDirection: "column",
                    padding: theme.spacing(FORM_DIALOG_PADDING),
                }),
            },
        },
        CcGroupBox: {
            styleOverrides: {
                legend: {
                    ...typography.caption,
                    fontSize: "calc(" + typography.caption.fontSize + " * 0.75)",
                    lineHeight: "calc(" + typography.caption.lineHeight + " * 0.75)",
                    letterSpacing: "calc(" +
                        typography.caption.letterSpacing.toString() +
                        "px * 0.75)",
                    color: "rgba(0, 0, 0, 0.6)",
                },
            },
        },
        CcFormPageLayout: {
            styleOverrides: {
                body: {
                    display: "none", // should be using SamedisFormPageLayout, not CcFormPageLayout, everywhere
                },
            },
        },
        CcBasicFormPage: {
            defaultProps: {
                formPageLayoutComponent: SamedisFormPageLayout,
            },
        },
        CcSubActionButton: {
            styleOverrides: {
                button: ({ theme }) => ({
                    backgroundColor: "transparent",
                    "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                    },
                }),
            },
        },
        CcBaseSelector: {
            styleOverrides: {
                listItem: {
                    padding: "4px 16px !important",
                },
                smallLabel: {
                    ...typography.caption2,
                },
            },
        },
        CcMultiSelectEntry: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                selected: {
                    paddingTop: 4,
                    paddingBottom: 4,
                },
            },
        },
        CcMultiSelect: {
            defaultProps: {
                confirmDelete: true,
                selectedSort: MultiSelectorDefaultSelectedSort,
            },
        },
        CcFileUpload: {
            defaultProps: {
                variant: "modern",
            },
            styleOverrides: {
                modernUploadLabel: {
                    "&.CcFileUpload-modernUploadLabel-empty": {
                        ...typography.caption2,
                    },
                },
                formatTextModern: {
                    ...typography.caption2,
                },
            },
        },
        CcFile: {
            styleOverrides: {
                listLabel: {
                    ...typography.body1,
                },
            },
        },
        CcMultiImage: {
            styleOverrides: {
                editLabel: {
                    ...typography.body1,
                },
            },
        },
        CcImageSelector: {
            defaultProps: {
                variant: "modern",
            },
            styleOverrides: {
                modernUploadControlUpload: {
                    color: customPalette.primary.main,
                    fill: customPalette.primary.main,
                },
                previewModern: {
                    aspectRatio: "16/9",
                },
                previewDialog: imagePreviewDialogStyles,
            },
        },
        CcImageBox: {
            styleOverrides: {
                fullScreenDialog: imagePreviewDialogStyles,
                image: {
                    aspectRatio: "16/9",
                },
            },
        },
        CcLocalizedKeyboardDatePicker: {
            defaultProps: {
                hideDisabledIcon: true,
            },
        },
        CcUiKitInput: {
            styleOverrides: ccInputStyles,
        },
        CcUiKitInputOutlined: {
            styleOverrides: ccInputStyles,
        },
        CcUiKitTextField: {
            styleOverrides: ccInputStyles,
        },
        CcDialogTitle: {
            styleOverrides: {
                text: {
                    ...typography.h2,
                },
            },
        },
        CcNotifications: {
            styleOverrides: {
                header: {
                    ...typography.h2,
                },
            },
        },
        CcCrud: {
            styleOverrides: {
                gridWrapper: {
                    display: "flex",
                    "&.Mui-hidden": {
                        display: "none",
                    },
                },
            },
        },
        CcDataGrid: {
            styleOverrides: {
                root: {
                    border: "none",
                    minHeight: 230,
                },
                header: {
                    borderWidth: "0 0 0 0",
                    padding: "0 0 15px 0",
                    marginTop: 2,
                },
                search: {
                    "& .MuiTextField-root": {
                        margin: 0,
                        "& .MuiInputBase-input": {
                            marginTop: "6px !important",
                            marginBottom: "6px !important",
                        },
                    },
                },
                cell: {
                    borderWidth: "0 0 1px 0",
                    "&.CcDataGrid-dataCell": {
                        borderColor: customColors.white,
                        backgroundColor: customColors.lighterBlue,
                    },
                    "&.CcDataGrid-dataCellSelected": {
                        backgroundColor: customColors.lightBlue,
                    },
                    "&.CcDataGrid-headerCell": {
                        borderWidth: 0,
                    },
                },
                footer: {
                    padding: "15px 0 0 0",
                },
                columnHeaderContentWrapper: {
                    fontSize: "0.75rem",
                    fontWeight: 500,
                },
                columnHeaderResizer: {
                    borderWidth: "0 1px 0 0",
                    top: "2px",
                    height: "calc(100% - 13px)",
                },
                customFilterContainerHeader: {
                    ...typography.subtitle1,
                },
                columnHeaderFilterButton: {
                    "&.CcDataGrid-columnHeaderFilterButtonActive": {
                        color: filterActiveColor,
                    },
                },
                quickFilterIcon: {
                    "&.CcDataGrid-quickFilterActiveIcon": {
                        color: filterActiveColor,
                    },
                },
                customFilterIcon: {
                    "&.CcDataGrid-customFilterActiveIcon": {
                        color: filterActiveColor,
                    },
                },
                customFilterSingle: {
                    ...dataGridFilterStyles,
                },
                customFilterSingleBackend: {
                    ...dataGridFilterStyles,
                },
                customFilterMulti: {
                    ...dataGridFilterStyles,
                },
                customFilterMultiBackend: {
                    ...dataGridFilterStyles,
                },
                centeredStickyTypography: {
                    "& .MuiTypography-h4": {
                        ...typography.subtitle1,
                    },
                    "& .MuiTypography-h5": {
                        ...typography.subtitle2,
                    },
                },
                settingsTableHead: {
                    "& .MuiTableCell-root": {
                        backgroundColor: customColors.lightGray,
                    },
                },
                settingsTableRow: {
                    "&:nth-of-type(even)": {
                        backgroundColor: customColors.lighterBlue,
                    },
                },
            },
        },
        CcGridMultiSelectFilter: {
            styleOverrides: {
                ...dataGridFilterBarStyles,
            },
        },
        CcGridMultiSelectFilterBackend: {
            styleOverrides: {
                ...dataGridFilterBarStyles,
            },
        },
        CcGridSingleSelectFilter: {
            styleOverrides: {
                ...dataGridFilterBarStyles,
            },
        },
        CcGridSingleSelectFilterBackend: {
            styleOverrides: {
                ...dataGridFilterBarStyles,
            },
        },
        CcComponentWithLabel: {
            defaultProps: {
                labelVariant: "caption2",
            },
        },
        CcDefaultFormPageButtons: {
            styleOverrides: {
                backButton: {
                    backgroundColor: customPalette.primary.main,
                    [`.${SamedisMobileFormButtonsWrapperMobileClassName} &`]: {
                        display: "none",
                    },
                },
            },
        },
        CcMultiLanguageInput: {
            styleOverrides: {
                languageLabelInputAdornment: {
                    ...typography.caption2,
                },
            },
        },
        CcForm: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexGrow: 1,
                    width: "100%",
                    flexDirection: "column",
                },
            },
        },
    },
    palette: {
        ...customPalette,
    },
});
