import { addGetParams } from "components-care";
import SystemController from "./controllers/SystemController";
import { openDB, } from "idb";
import * as Sentry from "@sentry/react";
import { ENABLE_OFFLINE_MODE } from "../../constants";
class OfflineDatabase {
    static lastSync = null;
    static controllers = [];
    static database;
    static init() {
        if (!ENABLE_OFFLINE_MODE)
            return;
        this.controllers.push(new SystemController());
        this.database = openDB("offline-db", 1, {
            upgrade(database, oldVersion, newVersion, transaction, event) {
                return Promise.all(OfflineDatabase.controllers.map((entry) => entry.upgradeStore(database, oldVersion, newVersion, transaction, event)));
            },
            blocked(currentVersion, blockedVersion, event) {
                Sentry.captureException(new Error(`OfflineDatabase.blocked(${currentVersion}, ${blockedVersion}, event)`), { extra: { event } });
            },
            terminated() {
                Sentry.captureException(new Error("OfflineDatabase.terminated()"));
            },
        });
    }
    static handleRequests(method, url, args, headers, body, _auth) {
        for (const controller of OfflineDatabase.controllers) {
            const result = controller.onRequest(method, new URL(addGetParams(url, args), window.location.origin), headers, body);
            if (result)
                return result;
        }
    }
    static cantSyncReason() {
        // TODO: Add checks for user preference
        // don't sync when saving data
        // @ts-expect-error not standard
        if (navigator.connection?.saveData)
            return "saveData";
        return navigator.onLine ? null : "offline";
    }
    static canSync() {
        return this.cantSyncReason() == null;
    }
    static async quota() {
        if (!("storage" in navigator) || !("estimate" in navigator.storage))
            return null;
        return navigator.storage.estimate();
    }
}
export default OfflineDatabase;
