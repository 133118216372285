import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import AuthProvider from "./components/AuthProvider";
import SamedisPortal from "./components/SamedisPortal";
import TenantRoutesProvider from "./components/TenantRoutesProvider";
import { DialogContextProvider, Route as SentryRoute, Routes as SentryRoutes, useParams, } from "components-care";
import TenantContextSetter from "./components/TenantContextSetter";
import getCurrentApp from "../utils/getCurrentApp";
const DeviceView = React.lazy(() => import("./LandingPage/LandingPageWrapper"));
const AnonymousRoutes = getCurrentApp() === "app"
    ? [
        {
            path: "/device-view/:id/*",
            component: DeviceView,
        },
    ]
    : [];
const AnonRouteComponent = (props) => {
    const params = useParams();
    const { component: Component } = props;
    return (_jsx(AuthProvider, { optional: true, children: _jsx(TenantContextSetter, { tenantId: params.tenantId ?? null, children: _jsx(DialogContextProvider, { children: _jsx(Component, {}) }) }) }));
};
const RootPage = () => {
    return (_jsx(SentryRoutes, { children: [
            ...AnonymousRoutes.map((route) => (_jsx(SentryRoute, { path: route.path, element: _jsx(AnonRouteComponent, { component: route.component }) }, route.path))),
            _jsx(SentryRoute, { path: "/*", element: _jsx(AuthProvider, { children: _jsx(TenantRoutesProvider, { children: _jsx(DialogContextProvider, { children: _jsx(SamedisPortal, {}) }) }) }) }, "app"),
        ] }));
};
export default React.memo(RootPage);
