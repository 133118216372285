import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Info as InfoIcon } from "@mui/icons-material";
import { backgroundGradient } from "../theme";
const useStyles = makeStyles()((theme) => ({
    icon: {
        color: theme.palette.background.paper,
        width: 56,
        height: 56,
    },
    altIcon: {
        color: theme.palette.background.paper,
        width: 24,
        height: 24,
    },
}));
const SamedisInfoBoxRoot = styled("div", {
    name: "SamedisInfoBox",
    slot: "root",
})(({ theme }) => ({
    padding: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    background: backgroundGradient,
    height: "100%",
    width: "100%",
}));
const SamedisInfoBoxRootAlt = styled("div", {
    name: "SamedisInfoBox",
    slot: "root-alt",
})(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    height: "100%",
    width: "100%",
}));
const SamedisInfoBoxList = styled("ul", {
    name: "SamedisInfoBox",
    slot: "root",
})(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: 0,
    paddingLeft: theme.spacing(2),
}));
const SamedisInfoBoxParagraph = styled(Typography, {
    name: "SamedisInfoBox",
    slot: "paragraph",
})({
    whiteSpace: "pre-wrap",
});
const SamedisInfoBox = (props) => {
    const { title, icon, content, contentType, altDesign } = props;
    const { classes } = useStyles();
    const Icon = icon ?? InfoIcon;
    const Root = altDesign ? SamedisInfoBoxRootAlt : SamedisInfoBoxRoot;
    return (_jsx(Root, { children: _jsxs(Grid, { container: true, spacing: altDesign ? 1 : 2, direction: altDesign ? "row-reverse" : "row", children: [_jsxs(Grid, { item: true, xs: true, children: [title && [
                            _jsx(SamedisInfoBoxParagraph, { component: "p", variant: altDesign ? "h2" : "subtitle1", children: title }, "title"),
                            !altDesign && _jsx("br", {}, "title-br1"),
                        ], contentType === "list" && (_jsx(SamedisInfoBoxList, { children: content.map((label, idx) => (_jsx(Typography, { component: "li", variant: altDesign ? "body1" : "subtitle1", children: label }, idx.toString(16)))) })), contentType === "paragraphs" &&
                            content.map((label, idx, arr) => [
                                _jsx(SamedisInfoBoxParagraph, { component: "p", variant: altDesign ? "body1" : "subtitle1", children: label }, idx.toString(16) + "-text"),
                                idx !== arr.length - 1 && _jsx("br", {}, idx.toString(16) + "-br"),
                            ])] }), _jsx(Grid, { item: true, children: _jsx(Icon, { className: altDesign ? classes.altIcon : classes.icon }) })] }) }));
};
export default React.memo(SamedisInfoBox);
