import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Avatar, Divider, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { destroySession, openProfile, redirectToLogin, useAuthProviderContext, } from "../AuthProvider";
import { AccountCircleOutlined as ProfileIcon, ExitToApp as LogoutIcon, Language as LangSwitchIcon, } from "@mui/icons-material";
import { LocaleSelectorDialog, useDialogContext } from "components-care";
import supportedLanguages from "../../../assets/data/supported-languages.json";
import TrustLevelCommunityIcon from "../../../components/icons/TrustLevelCommunityIcon";
import { openApp } from "../TenantRoutesProvider";
import getCurrentApp from "../../../utils/getCurrentApp";
const menuAnchorOrigin = {
    vertical: "top",
    horizontal: "right",
};
const menuTransformOrigin = {
    vertical: "top",
    horizontal: "right",
};
const ProfileMenu = (props) => {
    const { anchorEl, handleClose } = props;
    const [pushDialog] = useDialogContext();
    const { t } = useTranslation("portal");
    const authCtx = useAuthProviderContext();
    const handleOpenProfile = useCallback(() => {
        openProfile();
        handleClose();
    }, [handleClose]);
    const handleOpenLanguageDialog = useCallback(() => {
        pushDialog(_jsx(LocaleSelectorDialog, { supportedLocales: supportedLanguages }));
        handleClose();
    }, [pushDialog, handleClose]);
    const handleLogout = useCallback(async () => {
        await destroySession();
        redirectToLogin(false);
        handleClose();
    }, [handleClose]);
    const handleOpenMy = useCallback((evt) => {
        openApp(evt, true, "my");
        handleClose();
    }, [handleClose]);
    return (_jsxs(Menu, { open: !!anchorEl, anchorEl: anchorEl, keepMounted: true, onClose: handleClose, anchorOrigin: menuAnchorOrigin, transformOrigin: menuTransformOrigin, disableAutoFocusItem: true, children: [_jsxs(ListItem, { children: [_jsx(ListItemAvatar, { children: _jsx(Avatar, { src: authCtx.current_user.user_avatar ||
                                authCtx.current_user.image?.small ||
                                undefined }) }), _jsx(ListItemText, { children: [
                            authCtx.current_user.first_name,
                            authCtx.current_user.last_name,
                        ].join(" ") })] }), _jsx(Divider, {}), _jsx(ListItem, { children: _jsx(ListItemText, { primaryTypographyProps: { color: "textSecondary" }, children: authCtx.current_user.email }) }), getCurrentApp() !== "my" && (_jsxs(MenuItem, { onClick: handleOpenMy, onAuxClick: handleOpenMy, children: [_jsx(ListItemIcon, { children: _jsx(TrustLevelCommunityIcon, {}) }), _jsx(ListItemText, { primary: t("header.account.menu.my-samedis") })] })), _jsxs(MenuItem, { onClick: handleOpenProfile, children: [_jsx(ListItemIcon, { children: _jsx(ProfileIcon, {}) }), _jsx(ListItemText, { primary: t("header.account.menu.profile") })] }), _jsxs(MenuItem, { onClick: handleOpenLanguageDialog, children: [_jsx(ListItemIcon, { children: _jsx(LangSwitchIcon, {}) }), _jsx(ListItemText, { primary: t("header.account.menu.language") })] }), _jsxs(MenuItem, { onClick: handleLogout, children: [_jsx(ListItemIcon, { children: _jsx(LogoutIcon, {}) }), _jsx(ListItemText, { primary: t("header.account.menu.logout") })] })] }));
};
export default React.memo(ProfileMenu);
