import { isSubdomain } from "./index";
import { MY_APP_DOMAIN_PREFIX } from "../constants";
let currentApp = null;
const getCurrentApp = () => {
    return (currentApp ||= (() => {
        if (isSubdomain(MY_APP_DOMAIN_PREFIX))
            return "my";
        return "app";
    })());
};
export default getCurrentApp;
