import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogContent, Grid, useMediaQuery, useTheme, } from "@mui/material";
import FilterContainer from "./FilterContainer";
import BrowseContainer from "./BrowseContainer";
import { makeStyles } from "tss-react/mui";
import { DialogTitle, Loader, throwError, useAsyncCache, } from "components-care";
import { useTranslation } from "react-i18next";
import { useTenantIdOpt } from "../../../components/TenantContext";
import { StorageManager } from "components-care/dist/framework/Storage";
const useStyles = makeStyles()({
    unsize: {
        position: "relative",
        height: "100%",
    },
    unsize2: {
        position: "absolute",
        height: "100%",
        width: "100%",
        overflowY: "auto",
    },
});
export const STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_DEVICE = "SearchAndBrowseFilterDevice";
export const STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_USER = "SearchAndBrowseFilterUser";
const filterConfigToKey = (controlName, filter, moduleName, tenantId) => [
    controlName,
    ...[...(filter.persistKeys ?? [])]
        .sort()
        .map((k) => k === "module"
        ? (moduleName ?? throwError("module name required"))
        : k === "tenant"
            ? tenantId
                ? tenantId
                : throwError("tried using tenant persist mode without tenant")
            : throwError("invalid persist key")),
].join(",");
export const SearchAndBrowseNoBackgroundContext = React.createContext(false);
const SearchAndBrowse = (props) => {
    const { filters, moduleName, controlName, filterUserData, resetFilterUserData, filterDeviceData, resetFilterDeviceData, ...browseContainerProps } = props;
    const { t } = useTranslation("global-device-catalog");
    const { classes } = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    // filter persistence
    const tenantId = useTenantIdOpt();
    const [selectedFilters, setSelectedFilters] = useState(() => Object.fromEntries(Object.keys(filters).map((name) => {
        const filter = filters[name];
        const persistType = filter.persistType ?? "none";
        const filterKey = filterConfigToKey(controlName, filter, moduleName, tenantId);
        const persistDataSet = persistType === "none"
            ? null
            : persistType === "user"
                ? filterUserData[filterKey]
                : persistType === "device"
                    ? filterDeviceData[filterKey]
                    : throwError("unsupported persist type");
        const persistValue = persistDataSet &&
            JSON.parse(persistDataSet)[name];
        const defaultValue = filter.variant === "multi-select"
            ? []
            : filter.variant === "select"
                ? ""
                : false;
        return [name, persistValue ?? filter.defaultValue ?? defaultValue];
    })));
    const handleSelectedChange = (name, cb) => {
        setSelectedFilters((prev) => {
            const newValue = typeof cb === "function" ? cb(prev[name]) : cb;
            // update persistent data
            const filter = filters[name];
            const filterKey = filterConfigToKey(controlName, filter, moduleName, tenantId);
            if ((filter.persistType ?? "none") !== "none") {
                const data = {};
                for (const otherFilterName in filters) {
                    const otherFilter = filters[otherFilterName];
                    if (otherFilter.persistType !== filter.persistType)
                        continue;
                    if (filterConfigToKey(controlName, otherFilter, moduleName, tenantId) !== filterKey)
                        continue;
                    data[otherFilterName] = prev[otherFilterName];
                }
                data[name] = newValue;
                (async () => {
                    // update key and clear suspend-react cache
                    await StorageManager.setItem(filter.persistType === "user"
                        ? STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_USER
                        : filter.persistType === "device"
                            ? STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_DEVICE
                            : throwError("unsupported persist type"), { key: filterKey }, JSON.stringify(data));
                    resetFilterUserData();
                    resetFilterDeviceData();
                })();
            }
            return {
                ...prev,
                [name]: newValue,
            };
        });
    };
    const hasFilters = Object.values(filters).filter((filter) => !filter.hidden)
        .length > 0;
    const renderFilters = () => (_jsx(FilterContainer, { filters: Object.fromEntries(Object.entries(filters).map(([name, options]) => [
            name,
            {
                ...options,
                selected: selectedFilters[name],
                setSelected: handleSelectedChange,
            },
        ])) }));
    const handleOpenFilters = useCallback(() => {
        setFilterDialogOpen((prev) => !prev);
    }, []);
    const closeFilterDialog = useCallback(() => {
        setFilterDialogOpen(false);
    }, []);
    useEffect(() => {
        if (isSmallScreen)
            return;
        setFilterDialogOpen(false);
    }, [isSmallScreen]);
    return (_jsxs(Grid, { item: true, xs: true, container: true, spacing: 2, alignItems: "stretch", children: [isSmallScreen && filterDialogOpen && (_jsxs(Dialog, { open: true, fullScreen: true, onClose: closeFilterDialog, children: [_jsx(DialogTitle, { onClose: closeFilterDialog, children: t("filters.dialog-title") }), _jsx(DialogContent, { children: renderFilters() })] })), !isSmallScreen && hasFilters && (_jsx(Grid, { item: true, xs: 4, children: _jsx("div", { className: classes.unsize, children: _jsx("div", { className: classes.unsize2, children: renderFilters() }) }) })), _jsx(Grid, { item: true, xs: !isSmallScreen && hasFilters ? 8 : 12, container: true, direction: "column", children: _jsx(BrowseContainer, { filters: selectedFilters, ...browseContainerProps, onOpenFilters: isSmallScreen && hasFilters ? handleOpenFilters : undefined }) })] }));
};
const SearchAndBrowseOuter = (props) => {
    const { filters, moduleName, controlName } = props;
    // filter persistence
    const tenantId = useTenantIdOpt();
    // build a list of requested scopes
    const filterPersistKeysUserSet = new Set();
    const filterPersistKeysDeviceSet = new Set();
    for (const filterKey in filters) {
        const filter = filters[filterKey];
        if ((filter.persistType ?? "none") === "none")
            continue;
        if (filter.persistType === "user") {
            filterPersistKeysUserSet.add(filterConfigToKey(controlName, filter, moduleName, tenantId));
        }
        else if (filter.persistType === "device") {
            filterPersistKeysDeviceSet.add(filterConfigToKey(controlName, filter, moduleName, tenantId));
        }
        else {
            throw new Error("unsupported persist type");
        }
    }
    const filterPersistKeysUser = Array.from(filterPersistKeysUserSet).sort();
    const filterPersistKeysDevice = Array.from(filterPersistKeysDeviceSet).sort();
    // fetch data
    const { value: filterUserData, reset: resetFilterUserData } = useAsyncCache(async () => Object.fromEntries(await Promise.all(filterPersistKeysUser.map(async (key) => [
        key,
        await StorageManager.getItem(STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_USER, {
            key,
        }),
    ]))), filterPersistKeysUser, true);
    const { value: filterDeviceData, reset: resetFilterDeviceData } = useAsyncCache(async () => Object.fromEntries(await Promise.all(filterPersistKeysDevice.map(async (key) => [
        key,
        await StorageManager.getItem(STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_DEVICE, {
            key,
        }),
    ]))), filterPersistKeysDevice, true);
    if (!filterUserData || !filterDeviceData)
        return _jsx(Loader, {});
    return (_jsx(SearchAndBrowse, { ...props, filterUserData: filterUserData, resetFilterUserData: resetFilterUserData, filterDeviceData: filterDeviceData, resetFilterDeviceData: resetFilterDeviceData }));
};
export default React.memo(SearchAndBrowseOuter);
