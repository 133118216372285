import OfflineController from "../OfflineController";
class SystemController extends OfflineController {
    constructor() {
        super("system");
        this.routes["GET"]["/api/v4/maintenance"] = (request) => this.tryForward(request, () => this.makeOfflineResponse({
            samedis: { current: null, planned: [] },
        }));
        this.routes["GET"]["/api/v4/get_current_user"] = async (request) => {
            const db = await this.getDB();
            return this.tryForward(request, async (e) => {
                const offlineUser = (await db.get("system", "current_user"));
                if (!offlineUser)
                    throw e;
                offlineUser.data.current_user.image = this.rewriteImages(offlineUser.data.current_user.image);
                // add offline.mode cando to every tenant
                offlineUser.data.current_user.tenants.forEach((tenant) => tenant.candos.push("samedis-care/offline.mode"));
                return this.makeOfflineResponse(offlineUser, offlineUser.meta?.msg?.success ? 200 : 403);
            }, async (resp) => {
                const currentUser = (await resp.json());
                await db.put("system", currentUser, "current_user");
                if (currentUser.meta?.msg?.success) {
                    await this.cacheImages(currentUser.data.current_user.image);
                    await this.cacheImage(currentUser.data.current_user.user_avatar);
                }
            });
        };
    }
    upgradeStore(database, oldVersion, _newVersion, _transaction, _event) {
        if (oldVersion < 1) {
            database.createObjectStore("system");
        }
    }
}
export default SystemController;
