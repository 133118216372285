import { BackendError, RailsApiClient, sleep } from "components-care";
import AuthMode from "components-care/dist/backend-integration/Connector/AuthMode";
import { destroySession, handleAuth, } from "../../pages/components/AuthProvider";
import * as Sentry from "@sentry/react";
import OfflineDatabase from "../../api/offline/OfflineDatabase";
class SamedisApiClient extends RailsApiClient {
    constructor(interceptForOfflineSupport) {
        super(() => handleAuth(), async (response, responseData, method, url, args, body, auth) => {
            if (response.status === 429 /* too many requests */) {
                // wait and retry
                const retryAfter = response.headers.get("retry-after");
                let sleepSec = retryAfter ? parseInt(retryAfter) : NaN;
                if (isNaN(sleepSec)) {
                    console.log("429 too many requests handler: Retry-After header missing or invalid:", retryAfter, "=> defaulting to 10 second retry");
                    sleepSec = 10;
                }
                await sleep(sleepSec * 1000);
                return this.request(method, url, args, body, auth);
            }
            const rsp = responseData;
            // auth error in dev
            // noinspection RequiredAttributes
            const authError = "exception" in rsp &&
                rsp.exception ===
                    "#<ApplicationDocument::AuthenticationError: ApplicationDocument::AuthenticationError>";
            const success = rsp.meta?.msg?.success;
            const error = rsp.meta?.msg?.error;
            const message = rsp.meta?.msg?.message;
            if (!success) {
                if (authError ||
                    [
                        "invalid_token",
                        "token_invalid",
                        "token_expired",
                        "unauthenticated",
                    ].includes(error ?? "")) {
                    if (auth === AuthMode.Off) {
                        throw new Error("Authentication is needed, but wasn't specified");
                    }
                    if (auth !== AuthMode.Try) {
                        await destroySession();
                        // retry
                        return this.request(method, url, args, body, auth);
                    }
                }
                throw new BackendError(message || error || "Invalid response", error, rsp);
            }
            return responseData;
        }, undefined, undefined, (error) => {
            if (error.name === "NetworkError")
                return;
            if (error.name === "BackendError")
                return;
            Sentry.captureException(error);
        }, interceptForOfflineSupport ? OfflineDatabase.handleRequests : undefined);
    }
}
// Don't use this unless you're writing code for offline syncs
export const OnlineSamedisApiClient = new SamedisApiClient(false);
export default new SamedisApiClient(true);
