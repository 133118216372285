import { jsx as _jsx } from "react/jsx-runtime";
import { Grid, Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { combineClassNames } from "components-care";
const useStyles = makeStyles({ name: "BoxGrid" })((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
}));
const BoxGrid = (props) => {
    const { classNamePaper, classNameScroller, styleInner, classNameInner, ...gridProps } = props;
    const { classes } = useStyles();
    const paper = (_jsx(Paper, { className: combineClassNames([classes.paper, classNamePaper]), elevation: 0, children: props.container ? (_jsx(Grid, { ...gridProps, item: false, xs: undefined, sm: undefined, md: undefined, lg: undefined, xl: undefined, className: classNameInner, style: styleInner, children: props.children })) : (props.children) }));
    const scroller = classNameScroller ? (_jsx("div", { className: classNameScroller, children: paper })) : (paper);
    return props.item ? (_jsx(Grid, { ...gridProps, container: false, spacing: undefined, alignContent: undefined, alignItems: undefined, direction: undefined, justifyContent: undefined, wrap: undefined, children: scroller })) : (scroller);
};
export default BoxGrid;
