import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Grid, Typography } from "@mui/material";
import SamedisMenuGroup from "./SamedisMenuGroup";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { combineClassNames } from "components-care";
import { MenuContext } from "components-care/dist/standalone/Portal/Menu/MenuItem";
const useStyles = makeStyles()((theme) => ({
    root: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
    rootVertical: {
        padding: theme.spacing(1.5),
    },
    rootHorizontal: {
        padding: theme.spacing(2, 1),
    },
    clickable: {
        cursor: "pointer",
    },
    active: {
        borderTop: "2px solid " + theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
    inactive: {
        borderTop: "2px solid transparent", // consistent sizing
    },
    activeVertical: {
        color: theme.palette.primary.main,
    },
    arrowIcon: {
        verticalAlign: "middle",
    },
}));
const SamedisMenuItem = (props) => {
    const { item, menuItemId, vertical } = props;
    const { onClick, title, children, onAuxClick } = item;
    const [anchorEl, setAnchorEl] = useState(null);
    const { classes } = useStyles();
    const entryId = menuItemId ? menuItemId + "@" + item.title : item.title;
    const [activeEntry] = useContext(MenuContext);
    const isActive = activeEntry === entryId || activeEntry.startsWith(entryId + "@");
    const handleClick = useCallback((evt) => {
        setAnchorEl(evt.currentTarget);
        if (onClick)
            onClick(evt);
    }, [onClick]);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    const childrenWithCloseHandler = useMemo(() => children &&
        children.map((entry) => ({
            ...entry,
            onClick: (evt) => {
                handleClose();
                if (entry.onClick)
                    entry.onClick(evt);
            },
        })), [children, handleClose]);
    return (_jsxs(_Fragment, { children: [childrenWithCloseHandler && anchorEl && (_jsx(SamedisMenuGroup, { itemDefs: childrenWithCloseHandler, vertical: true, anchorEl: anchorEl, onClose: handleClose, menuItemId: entryId })), _jsx(Grid, { container: true, alignContent: "center", alignItems: "center", onClick: handleClick, onAuxClick: onAuxClick, className: combineClassNames([
                    classes.root,
                    vertical ? classes.rootVertical : classes.rootHorizontal,
                    onClick && classes.clickable,
                    vertical
                        ? isActive
                            ? classes.activeVertical
                            : false
                        : isActive
                            ? classes.active
                            : classes.inactive,
                ]), children: _jsx(Grid, { item: true, children: _jsxs(Typography, { variant: "subtitle1", children: [title, item.children &&
                                (anchorEl ? (_jsx(ArrowDropUp, { className: classes.arrowIcon })) : (_jsx(ArrowDropDown, { className: classes.arrowIcon })))] }) }) })] }));
};
export default React.memo(SamedisMenuItem);
