export function unregister() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
            return registration.unregister();
        })
            .catch((error) => {
            console.error(error.message);
        });
    }
}
