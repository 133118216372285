import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useDevKeybinds } from "components-care";
const handlers = {
    "samedis enable developer mode": () => {
        localStorage.setItem("SAMEDIS_DEV_MODE", "true");
        window.location.reload();
    },
    "samedis disable developer mode": () => {
        localStorage.setItem("SAMEDIS_DEV_MODE", "false");
        window.location.reload();
    },
};
const DevMode = (props) => {
    useDevKeybinds(handlers);
    return _jsx(_Fragment, { children: props.children });
};
export default React.memo(DevMode);
