import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { useMaintenanceInfo } from "../../../utils/MaintenanceMode";
import { Grid, IconButton, Paper, Typography, useMediaQuery, useTheme, } from "@mui/material";
import { Build as Icon } from "@mui/icons-material";
import { useDialogContext, combineClassNames, FullFormDialog, } from "components-care/dist";
import MaintenancePage from "../../../components/maintenance/MaintenancePage";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles()((theme) => ({
    root: {
        paddingTop: `calc(${theme.spacing(1)} / 2)`,
        paddingBottom: `calc(${theme.spacing(1)} / 2)`,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        cursor: "pointer",
    },
    active: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.getContrastText(theme.palette.error.main),
    },
    helpIcon: {
        display: "block",
        marginLeft: theme.spacing(1),
    },
    activeIcon: {
        color: theme.palette.error.main,
    },
}));
export const useIsMaintenanceIconVisible = () => {
    const maintenanceInfo = useMaintenanceInfo();
    return maintenanceInfo.current || maintenanceInfo.planned.length > 0;
};
const MaintenanceIcon = () => {
    const maintenanceInfo = useMaintenanceInfo();
    const isMaintenanceIconVisible = useIsMaintenanceIconVisible();
    const [pushDialog, popDialog] = useDialogContext();
    const { classes } = useStyles();
    const { t } = useTranslation("common");
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("md"));
    const openMaintenanceInfoDialog = useCallback(() => {
        pushDialog(_jsx(FullFormDialog, { maxWidth: "lg", dialogTitle: t("maintenance.header"), useCustomClasses: true, onClose: popDialog, children: _jsx(MaintenancePage, { dialog: true }) }));
    }, [pushDialog, popDialog, t]);
    return (_jsx(_Fragment, { children: isMaintenanceIconVisible &&
            (small ? (_jsx(IconButton, { onClick: openMaintenanceInfoDialog, className: combineClassNames([
                    maintenanceInfo.current && classes.activeIcon,
                ]), size: "small", children: _jsx(Icon, {}) })) : (_jsx(Paper, { onClick: openMaintenanceInfoDialog, className: combineClassNames([
                    classes.root,
                    maintenanceInfo.current && classes.active,
                ]), children: _jsxs(Grid, { container: true, spacing: 0, justifyContent: "center", alignItems: "center", children: [_jsx(Grid, { item: true, children: _jsx(Typography, { children: maintenanceInfo.current
                                    ? t("maintenance.active")
                                    : t("maintenance.planned") }) }), _jsx(Grid, { item: true, children: _jsx(Icon, { className: classes.helpIcon }) })] }) }))) }));
};
export default React.memo(MaintenanceIcon);
