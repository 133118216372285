import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { FormControlLabel, Grid, Paper, Switch, Typography, } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Checkbox } from "components-care";
const useStyles = makeStyles()((theme) => ({
    paper: {
        border: `1px solid ${theme.palette.divider}`,
        padding: `0 ${theme.spacing(2)}`,
    },
}));
const FilterEntrySwitch = (props) => {
    const { name, selected, setSelected, variant, title } = props;
    const { classes } = useStyles();
    const handleSelectionChange = useCallback((_evt, checked) => {
        setSelected(name, checked);
    }, [setSelected, name]);
    const renderControl = () => {
        if (variant === "switch") {
            return _jsx(Switch, { checked: selected, onChange: handleSelectionChange });
        }
        if (variant === "checkbox") {
            return _jsx(Checkbox, { checked: selected, onChange: handleSelectionChange });
        }
        throw new Error("Unsupported variant");
    };
    return (_jsx(Grid, { item: true, children: _jsx(Paper, { elevation: 0, className: classes.paper, children: _jsx(FormControlLabel, { control: renderControl(), label: _jsx(Typography, { children: title }) }) }) }));
};
export default React.memo(FilterEntrySwitch);
