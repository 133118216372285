import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useTenantContext } from "./TenantContext";
import { usePermissionContext } from "components-care";
import { useAuthProviderContext } from "./AuthProvider";
import { normalizeCanDos } from "../../utils";
const TenantContextSetter = (props) => {
    const authInfo = useAuthProviderContext();
    const [activeTenant, setActiveTenant] = useTenantContext();
    const [, setPermissions] = usePermissionContext();
    useEffect(() => {
        setActiveTenant(props.tenantId);
        const tenant = authInfo.current_user.tenants.find((tenant) => tenant.id === props.tenantId);
        setPermissions(tenant ? normalizeCanDos(tenant.candos) : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tenantId]);
    return activeTenant === props.tenantId ? props.children : _jsx(_Fragment, {});
};
export default TenantContextSetter;
