import { ModelDataTypeBackendDataGridMultiSelectRenderer, ModelDataTypeBackendMultiSelectRenderer, ModelDataTypeBackendMultiSelectWithTagsRenderer, ModelDataTypeBackendSingleSelectRenderer, } from "components-care";
// @ts-expect-error custom filter type
const getFilterType = () => "object_id";
ModelDataTypeBackendDataGridMultiSelectRenderer.prototype.getFilterType =
    getFilterType;
ModelDataTypeBackendMultiSelectRenderer.prototype.getFilterType = getFilterType;
ModelDataTypeBackendMultiSelectWithTagsRenderer.prototype.getFilterType =
    getFilterType;
ModelDataTypeBackendSingleSelectRenderer.prototype.getFilterType =
    getFilterType;
