import { ApiConnector, isObjectEmpty, ModelDataTypeImageRenderer, showInfoDialog, uniqueArray, } from "components-care";
import SamedisApiClient from "./SamedisApiClient";
import { ExcelExportIcon } from "../../components/icons";
import i18n from "../../i18n";
import AuthMode from "components-care/dist/backend-integration/Connector/AuthMode";
import { isSessionValid } from "../../pages/components/AuthProvider";
class BackendConnector extends ApiConnector {
    apiBase;
    controller;
    includedRelations;
    includedRelationsReverse;
    additionalQueryParameters;
    putTag;
    forceFieldFilter;
    columns;
    putInsteadOfPost;
    getEndpointOverrideCreate;
    getEndpointOverrideUpdate;
    deserializeOverride;
    rewriteIndexRequest;
    singleton;
    // can be set via configureConnector
    optionalAuth;
    /**
     * Initializes the backend connector
     * @param controller The backend controller which should be used as endpoint
     * @param putTag Top level tag name for data in PUT/POST requests or NULL for no top level tag (OBSOLETE)
     * @param options The options
     */
    constructor(controller, putTag = "data", options) {
        super();
        this.controller = controller;
        this.apiBase = "/api/" + controller;
        this.putTag = putTag;
        this.includedRelations = options?.includedRelations ?? {};
        this.includedRelationsReverse = {};
        this.additionalQueryParameters = options?.additionalQueryParameters;
        this.forceFieldFilter = options?.forceFieldFilter;
        this.columns = options?.columns;
        this.putInsteadOfPost = options?.putInsteadOfPost ?? false;
        this.getEndpointOverrideCreate = options?.getEndpointOverrideCreate;
        this.getEndpointOverrideUpdate = options?.getEndpointOverrideUpdate;
        this.deserializeOverride = options?.deserializeOverride;
        this.rewriteIndexRequest =
            options?.rewriteIndexRequest ??
                ((record) => record);
        this.singleton = options?.singleton ?? false;
        if (this.additionalQueryParameters &&
            "include" in this.additionalQueryParameters) {
            throw new Error("include cannot be set via additionalQueryParameters, use includedRelations struct instead");
        }
        Object.entries(this.includedRelations).forEach(([field, meta]) => {
            const type = meta[0];
            if (type in this.includedRelationsReverse) {
                this.includedRelationsReverse[type].push(field);
            }
            else {
                this.includedRelationsReverse[type] = [field];
            }
        });
    }
    getApiBase = () => {
        return this.apiBase;
    };
    getController = () => {
        return this.controller;
    };
    getAuthMode() {
        return this.optionalAuth
            ? isSessionValid()
                ? AuthMode.Try
                : AuthMode.Off
            : AuthMode.On;
    }
    convertSort = (sort) => ({
        property: sort.field,
        direction: sort.direction < 0 ? "DESC" : "ASC",
    });
    toAgGridFilterType = (filterType) => {
        switch (filterType) {
            case "string":
            case "localized-string":
            case "combined-string":
            case "enum":
                return "text";
            case "number":
                return "number";
            case "date":
                return "date";
            case "datetime":
                return "datetime";
            case "boolean":
                return "bool";
            default:
                if (filterType) {
                    if (["object_id"].includes(filterType))
                        return "object_id";
                }
                throw new Error("not supported by backend");
        }
    };
    toAgGridFilterDef = (filter, filterType) => ({
        filterType,
        type: filter.type,
        [filterType === "date"
            ? "dateFrom"
            : filterType === "datetime"
                ? "dateTimeFrom"
                : "filter"]: ["inSet", "notInSet"].includes(filter.type)
            ? filter.value1.split(",")
            : filter.value1,
        [filterType === "date"
            ? "dateTo"
            : filterType === "datetime"
                ? "dateTimeTo"
                : "filterTo"]: filter.value2 || undefined,
    });
    isFilterValid = (filter) => {
        if (!filter)
            return false;
        if (!filter.value1)
            return false;
        if (filter.type === "inRange" && !filter.value2)
            return false;
        return true;
    };
    getIndexParams(params) {
        params = this.rewriteIndexRequest(params);
        let { extraParams, gridFilter } = params;
        const { model, columns, pageIsOffset, page, sort, rows, quickFilter, additionalFilters, } = params;
        if (!extraParams)
            extraParams = {};
        const dataGridColumns = gridFilter &&
            (columns ?? model?.toDataGridColumnDefinition(true) ?? this.columns);
        // force grid filter
        gridFilter = Object.assign({}, gridFilter, this.forceFieldFilter);
        return Object.assign({
            [pageIsOffset ? "page[padding]" : "page[number]"]: page ?? undefined,
            "page[limit]": rows ?? undefined,
            sort: JSON.stringify(sort
                .map((sort) => {
                if (!dataGridColumns)
                    return sort;
                const filterTypeCC = dataGridColumns.find((entry) => entry.field === sort.field)?.type;
                if (filterTypeCC !== "localized-string")
                    return sort;
                return {
                    ...sort,
                    field: sort.field.replace("_translations", "") +
                        "." +
                        i18n.language.split("-")[0],
                };
            })
                .map(this.convertSort)),
            quickfilter: quickFilter,
            gridfilter: Object.fromEntries(Object.entries(gridFilter).map(([field, filter]) => {
                if (!this.isFilterValid(filter))
                    return [field, undefined];
                const filterTypeCC = dataGridColumns.find((entry) => entry.field === field)?.type;
                if (!filterTypeCC) {
                    console.error("BackendConnector: requested filter for column that is not defined:", field);
                    return [field, undefined];
                }
                const filterType = this.toAgGridFilterType(filterTypeCC);
                const agGridFilter = this.isFilterValid(filter.nextFilter)
                    ? {
                        condition1: this.toAgGridFilterDef(filter, filterType),
                        condition2: this.toAgGridFilterDef(filter.nextFilter, filterType),
                        filterType,
                        operator: filter.nextFilterType.toUpperCase(),
                    }
                    : this.toAgGridFilterDef(filter, filterType);
                return [
                    filterTypeCC === "localized-string"
                        ? `${field.replace("_translations", "")}.${i18n.language.split("-")[0]}`
                        : field,
                    agGridFilter,
                ];
            })),
            ...this.additionalQueryParameters,
            ...additionalFilters,
        }, extraParams);
    }
    async index(params, model) {
        // load reasonable defaults if nothing is set
        if (!params)
            params = {};
        if (!params.page)
            params.page = 1;
        if (params.rows == null)
            params.rows = 25;
        if (!params.sort)
            params.sort = [];
        if (!params.quickFilter)
            params.quickFilter = "";
        if (!params.fieldFilter)
            params.fieldFilter = {};
        if (!params.additionalFilters)
            params.additionalFilters = {};
        const indexParams = this.getIndexParams({
            page: params.page,
            rows: params.rows,
            sort: params.sort,
            quickFilter: params.quickFilter,
            gridFilter: params.fieldFilter,
            additionalFilters: params.additionalFilters,
            model,
        });
        return this.indexCommon(indexParams, model);
    }
    async index2(params, model) {
        // load reasonable defaults if nothing is set
        if (!params.sort)
            params.sort = [];
        if (!params.quickFilter)
            params.quickFilter = "";
        if (!params.fieldFilter)
            params.fieldFilter = {};
        if (!params.additionalFilters)
            params.additionalFilters = {};
        const indexParams = this.getIndexParams({
            page: params.offset,
            rows: params.rows,
            sort: params.sort,
            quickFilter: params.quickFilter,
            gridFilter: params.fieldFilter,
            additionalFilters: params.additionalFilters,
            model,
            pageIsOffset: true,
        });
        return this.indexCommon(indexParams, model);
    }
    async indexCommon(indexParams, model) {
        if (this.singleton)
            throw new Error("Backend connector in singleton mode, index disabled");
        const resp = await SamedisApiClient.get(this.getApiBase(), indexParams, this.getAuthMode());
        return [
            await Promise.all(resp.data.map((entry) => this.completeAttributes(Object.assign({}, entry.attributes, { id: entry.id }, entry.links), model))),
            {
                totalRows: resp.meta.total,
            },
            resp.meta,
        ];
    }
    getQueryParameters() {
        return isObjectEmpty(this.includedRelations)
            ? (this.additionalQueryParameters ?? null)
            : {
                ...this.additionalQueryParameters,
                include: uniqueArray(Object.values(this.includedRelations).map((entry) => entry[1])).join(","),
            };
    }
    async processDataResponse(resp, model) {
        const included = {};
        for (const relation in this.includedRelationsReverse) {
            const listOfFields = this.includedRelationsReverse[relation];
            listOfFields.forEach((field) => (included[field] = []));
        }
        if (resp.included) {
            resp.included.forEach((entry) => {
                const data = Object.assign({}, entry.attributes, { id: entry.id }, entry.links);
                const listOfFields = this.includedRelationsReverse[entry.type] ?? [];
                listOfFields.forEach((field) => {
                    included[field].push(data);
                    if (!(field in included)) {
                        included[field] = [data];
                    }
                });
            });
        }
        const relationIds = {};
        if ("relationships" in resp.data && resp.data.relationships) {
            Object.values(resp.data.relationships)
                .map((data) => data.data)
                .filter((data) => Array.isArray(data))
                .flat()
                .forEach((entry) => {
                const fieldName = entry.type + "_ids";
                if (fieldName in relationIds) {
                    relationIds[fieldName].push(entry.id);
                }
                else {
                    relationIds[fieldName] = [entry.id];
                }
            });
        }
        return [
            await this.completeAttributes(Object.assign({}, relationIds, resp.data.attributes, { id: resp.data.id }, resp.data.links), model),
            included,
            resp.meta,
        ];
    }
    async completeAttributes(data, model) {
        if (this.deserializeOverride)
            data = this.deserializeOverride(data);
        if (!model)
            return data;
        // fill missing fields
        for (const key in model.fields) {
            if (!Object.prototype.hasOwnProperty.call(model.fields, key))
                continue;
            if (!(key in data)) {
                data[key] = await (model.fields[key].getDefaultValue ??
                    model.fields[key].type.getDefaultValue)();
            }
        }
        return data;
    }
    async create(data, model) {
        if (this.singleton)
            throw new Error("BackendConnector is in singleton mode, create disabled");
        const resp = await SamedisApiClient[this.putInsteadOfPost ? "put" : "post"](this.getEndpointOverrideCreate
            ? this.getEndpointOverrideCreate(data)
            : this.getApiBase(), this.getQueryParameters(), this.putTag ? { [this.putTag]: data } : data, this.getAuthMode());
        return this.processDataResponse(resp, model);
    }
    async read(id, model) {
        const resp = await SamedisApiClient.get(this.singleton ? this.getApiBase() : `${this.getApiBase()}/${id}`, this.getQueryParameters(), this.getAuthMode());
        return this.processDataResponse(resp, model);
    }
    async update(data, model) {
        // remove not updated images
        if (model) {
            for (const keyRaw in data) {
                if (!Object.prototype.hasOwnProperty.call(data, keyRaw))
                    continue;
                const key = keyRaw;
                if (model.fields[key]?.type instanceof ModelDataTypeImageRenderer) {
                    if (data[key] && !data[key].startsWith("data:")) {
                        delete data[key];
                    }
                }
            }
        }
        // can't change the ID, so no need to send it
        const id = data.id;
        delete data["id"];
        const resp = await SamedisApiClient.put(this.getEndpointOverrideUpdate
            ? this.getEndpointOverrideUpdate({ ...data, id })
            : this.singleton
                ? this.getApiBase()
                : `${this.getApiBase()}/${id}`, this.getQueryParameters(), this.putTag ? { [this.putTag]: data } : data, this.getAuthMode());
        return this.processDataResponse(resp, model);
    }
    async delete(id) {
        return SamedisApiClient.delete(this.singleton ? this.getApiBase() : `${this.getApiBase()}/${id}`, this.additionalQueryParameters ?? null, this.getAuthMode());
    }
    async deleteMultiple(ids) {
        const promises = [];
        while (ids.length !== 0) {
            const batchIds = ids.splice(0, 250);
            promises.push(SamedisApiClient.delete(`${this.getApiBase()}/${batchIds.join(",")}`, this.additionalQueryParameters ?? null, this.getAuthMode()));
        }
        await Promise.all(promises);
    }
    dataGridExporters = [
        {
            id: "excel",
            icon: ExcelExportIcon,
            getLabel: () => i18n.t("common:data-grid.export.excel.label"),
            getWorkingLabel: () => i18n.t("common:data-grid.export.excel.working"),
            getReadyLabel: () => i18n.t("common:data-grid.export.excel.ready"),
            getErrorLabel: () => i18n.t("common:data-grid.export.excel.error"),
            onRequest: async (quickFilter, additionalFilters, fieldFilter, sort, columns) => {
                const indexParams = this.getIndexParams({
                    page: null,
                    rows: null,
                    sort,
                    quickFilter,
                    gridFilter: fieldFilter,
                    additionalFilters,
                    extraParams: {
                        "export[columns]": columns.map((col) => col.field).join(","),
                        locale: i18n.language,
                    },
                    columns,
                });
                return SamedisApiClient.get(this.getApiBase() + ".xlsx", indexParams, this.getAuthMode());
            },
            onDownload: (_data, pushDialog) => {
                if (!pushDialog)
                    throw new Error("pushDialog is " + pushDialog);
                void showInfoDialog(pushDialog, {
                    title: i18n.t("common:data-grid.export.excel.download.title"),
                    message: i18n.t("common:data-grid.export.excel.download.message"),
                });
            },
            autoDownload: true,
        },
    ];
    setApiEndpoint(url) {
        this.apiBase = "/api/" + url;
    }
}
export default BackendConnector;
