import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { IconButton } from "@mui/material";
import { Help as HelpIcon } from "@mui/icons-material";
import useIsMobile from "../../utils/useIsMobile";
const SupportPopover = () => {
    const supportChat = "https://samedis.care/im_livechat/support/2";
    const mobile = useIsMobile();
    return (_jsx(IconButton, { href: supportChat, target: "_blank", size: mobile ? "small" : "large", children: _jsx(HelpIcon, {}) }));
};
export default React.memo(SupportPopover);
