import { ModelDataTypeImageRenderer } from "components-care";
const deserialize = (value) => {
    return value ?? "";
};
const serialize = (value) => {
    return value ? value : null;
};
// @ts-expect-error unsupported
ModelDataTypeImageRenderer.prototype.serialize = serialize;
// @ts-expect-error unsupported
ModelDataTypeImageRenderer.prototype.deserialize = deserialize;
