import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useState } from "react";
import { useAuthProviderContext } from "./AuthProvider";
const TenantContext = React.createContext(undefined);
export const useTenantContext = () => {
    const ctx = useContext(TenantContext);
    if (!ctx)
        throw new Error("Tenant context not set");
    return ctx;
};
export const useTenantIdOpt = () => {
    const [tenantId] = useTenantContext();
    return tenantId;
};
export const useTenantId = () => {
    const tenantId = useTenantIdOpt();
    if (!tenantId)
        throw new Error("Tenant ID not set");
    return tenantId;
};
export const useTenantIdWithContextOpt = (tenantId) => {
    const optId = useTenantIdOpt();
    if (optId)
        return optId;
    return tenantId ?? null;
};
export const useTenantIdWithContext = (tenantId) => {
    tenantId = useTenantIdWithContextOpt(tenantId);
    if (!tenantId)
        throw new Error("Tenant ID not set");
    return tenantId;
};
export const useTenantInfo = () => {
    const tenantId = useTenantIdOpt();
    const authCtx = useAuthProviderContext();
    if (!tenantId)
        return null;
    const tenant = authCtx.current_user.tenants.find((tenant) => tenant.id === tenantId);
    if (!tenant)
        return null; // may be caused by data race, so no throw new Error("Tenant not present in auth context");
    return tenant;
};
const TenantContextProvider = (props) => {
    const tenantState = useState(null);
    return (_jsx(TenantContext.Provider, { value: tenantState, children: props.children }));
};
export default React.memo(TenantContextProvider);
