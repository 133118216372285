import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles()({
    grid: {
        height: "100%",
    },
});
const NotFound = () => {
    const { classes } = useStyles();
    const { t } = useTranslation("common");
    return (_jsx(Grid, { container: true, direction: "row", justifyContent: "center", alignItems: "center", className: classes.grid, children: _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "h1", align: "center", children: t("errors.notfound.title") }), _jsx(Typography, { variant: "subtitle1", align: "center", children: t("errors.notfound.msg") })] }) }));
};
export default React.memo(NotFound);
