import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { AccountCircleOutlined, Domain as TenantIcon, } from "@mui/icons-material";
import { openProfile, useAuthProviderContext, } from "../../components/AuthProvider";
import TenantListEntry from "./FacilityListEntry";
import { useTranslation } from "react-i18next";
import { useOpenInvites } from "../../../api/samedis/Invites";
import PolicyLinks from "./PolicyLinks";
import CreateTenantExplainer from "./CreateFacilityExplainer";
import NoTenantExplainer from "./NoFacilitiesExplainer";
import { useLocation, useNavigate, VerticalDivider } from "components-care";
import { makeStyles } from "tss-react/mui";
import BasicPageLayout from "../../../components/BasicPageLayout";
import PersonIcon from "../../../components/icons/PersonIcon";
import { openApp } from "../../components/TenantRoutesProvider";
import useIsMobile from "../../../utils/useIsMobile";
const useStyles = makeStyles()({
    listColumn: {
        maxWidth: "300px !important",
    },
});
const FacilityList = () => {
    const authInfo = useAuthProviderContext();
    const { t } = useTranslation("tenant-select");
    const navigate = useNavigate();
    const tenant_id = localStorage.getItem("tenant");
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const tenantRedirectURL = query.get("tenant_redirect");
    const { data: invites } = useOpenInvites();
    const { classes } = useStyles();
    const isMobile = useIsMobile();
    // use cached tenant to redirect directly upon page visit if it is valid
    // navigate must be used in useEffect
    useEffect(() => {
        if (tenant_id &&
            authInfo.current_user.tenants.find((tenant) => tenant.id === tenant_id)) {
            navigate(`/tenant/${tenant_id}${tenantRedirectURL ?? ""}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authInfo]);
    const goToTenantCreate = useCallback(() => {
        navigate("/new-tenant");
    }, [navigate]);
    const openMySamedis = useCallback((evt) => {
        openApp(evt, true, "my");
    }, []);
    const canCreateTenant = authInfo.candos.includes("samedis-care/tenants.create" /* current */) ||
        authInfo.candos.includes("samedis-care/tenant.create" /* legacy */);
    const recoveredAccount = authInfo.current_user.recovered_account;
    return (_jsxs(BasicPageLayout, { spacing: 2, title: t("select"), children: [_jsx(Grid, { item: true, xs: 12, sm: 5, className: classes.listColumn, children: _jsxs(Grid, { container: true, spacing: 2, alignItems: "flex-start", children: [authInfo.current_user.tenants.length === 0 && (_jsx(Grid, { item: true, xs: 12, children: _jsx(NoTenantExplainer, {}) }, "no-tenant")), authInfo.current_user.tenants.map((tenant) => (_jsx(Grid, { item: true, xs: 12, children: _jsx(TenantListEntry, { id: tenant.id, image: tenant.image.small ?? _jsx(TenantIcon, { color: "primary" }), text: tenant.short_name || tenant.full_name, subtext: tenant.town }) }, tenant.id))), invites?.map((invite) => (_jsx(Grid, { item: true, xs: 12, children: _jsx(TenantListEntry, { id: invite.tenant_id, image: invite.tenant.image ?? _jsx(TenantIcon, { color: "primary" }), text: invite.tenant.name || invite.tenant.name2, invite: invite }) }, invite.tenant_id))), canCreateTenant && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "subtitle1", color: "background.paper", children: t("create-title") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(CreateTenantExplainer, {}) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TenantListEntry, { id: "new", image: _jsx(TenantIcon, { color: "primary" }), text: t("create"), onClick: goToTenantCreate }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(PolicyLinks, {}) })] }))] }) }), isMobile ? (_jsx(Grid, { item: true, xs: 12, className: classes.listColumn, children: _jsx(Divider, {}) })) : (_jsx(Grid, { item: true, children: _jsx(VerticalDivider, {}) })), _jsx(Grid, { item: true, xs: 12, sm: 5, className: classes.listColumn, children: _jsxs(Grid, { container: true, spacing: 2, alignItems: "flex-start", children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(TenantListEntry, { id: "my-profile", image: authInfo.current_user.user_avatar ??
                                    authInfo.current_user.image?.small ?? (_jsx(PersonIcon, { color: "primary" })), text: [
                                    authInfo.current_user.first_name,
                                    authInfo.current_user.last_name,
                                ].join(" "), subtext: t("open_my-samedis"), onClick: openMySamedis }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TenantListEntry, { id: "my-account", image: _jsx(AccountCircleOutlined, { color: recoveredAccount ? undefined : "primary", htmlColor: recoveredAccount ? "white" : undefined }), text: recoveredAccount
                                    ? t("my_account.text_recover")
                                    : t("my_account.text"), subtext: t("my_account.subtext"), onClick: openProfile, recovery: recoveredAccount }) }), recoveredAccount && (_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { color: "background.paper", children: t("my_account.recovery_hint") }) }))] }) })] }));
};
export default React.memo(FacilityList);
