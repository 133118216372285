import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { useAuthProviderContext } from "../AuthProvider";
import ProfileMenu from "./ProfileMenu";
import { makeStyles } from "tss-react/mui";
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon, Person as AccountIcon, } from "@mui/icons-material";
import { Avatar, Grid, Hidden, IconButton } from "@mui/material";
const useStyles = makeStyles()((theme) => ({
    root: {
        cursor: "pointer",
    },
    name: {
        ...theme.typography.subtitle1,
        whiteSpace: "nowrap",
    },
    arrowIcon: {
        width: 16,
        height: 16,
        verticalAlign: "middle",
    },
    pfpImage: {
        height: theme.spacing(3),
        width: theme.spacing(3),
    },
}));
const AccountActions = () => {
    const { classes } = useStyles();
    const authInfo = useAuthProviderContext();
    const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
    const openProfileMenu = useCallback((evt) => setProfileMenuAnchor(evt.currentTarget), [setProfileMenuAnchor]);
    const closeProfileMenu = useCallback(() => setProfileMenuAnchor(null), [setProfileMenuAnchor]);
    return (_jsxs(_Fragment, { children: [_jsx(Hidden, { mdDown: true, implementation: "js", children: _jsxs(Grid, { container: true, onClick: openProfileMenu, wrap: "nowrap", alignItems: "center", alignContent: "center", spacing: 1, className: classes.root, children: [_jsx(Grid, { item: true, children: _jsx(Avatar, { src: authInfo.current_user.user_avatar ??
                                    authInfo.current_user.image?.small ??
                                    undefined, className: classes.pfpImage }) }), _jsx(Grid, { item: true, children: _jsxs("span", { className: classes.name, children: [authInfo.current_user.first_name, " ", authInfo.current_user.last_name, profileMenuAnchor ? (_jsx(ArrowDropUpIcon, { className: classes.arrowIcon })) : (_jsx(ArrowDropDownIcon, { className: classes.arrowIcon }))] }) })] }) }), _jsx(Hidden, { mdUp: true, implementation: "js", children: _jsx(IconButton, { onClick: openProfileMenu, size: "small", children: _jsx(AccountIcon, {}) }) }), _jsx(ProfileMenu, { anchorEl: profileMenuAnchor, handleClose: closeProfileMenu })] }));
};
export default React.memo(AccountActions);
