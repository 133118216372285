import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Divider, Grid, Menu } from "@mui/material";
import SamedisMenuItem from "./SamedisMenuItem";
import { makeStyles } from "tss-react/mui";
import TenantSelector from "./header/FacilitySelector";
const useStyles = makeStyles()((theme) => ({
    verticalWrapper: {
        position: "relative",
    },
    dividerTop: {
        position: "absolute",
        top: `calc(${theme.spacing(1)} + 1px)`,
        left: 0,
        right: 0,
        borderTop: "1px solid " + theme.palette.divider,
        zIndex: -1,
    },
    tenant: {
        padding: theme.spacing(1),
    },
}));
const shouldRenderItem = (item) => item.shouldRender;
const SamedisMenuGroup = (props) => {
    const { vertical, anchorEl, onClose, includeTenantHeader, itemDefs, menuItemId, } = props;
    const { classes } = useStyles();
    if (!vertical && includeTenantHeader)
        throw new Error("not implemented");
    if (!vertical)
        return (_jsxs(Grid, { item: true, container: true, spacing: 1, alignContent: "center", alignItems: "center", className: classes.verticalWrapper, children: [_jsx("div", { className: classes.dividerTop }), itemDefs.filter(shouldRenderItem).map((item, idx) => (_jsx(Grid, { item: true, children: _jsx(SamedisMenuItem, { item: item, vertical: vertical, menuItemId: menuItemId }) }, item.route || idx)))] }));
    return (_jsx(Menu, { anchorEl: anchorEl, open: !!anchorEl, onClose: onClose, children: _jsxs(Grid, { container: true, direction: "column", children: [includeTenantHeader && (_jsx(Grid, { item: true, zeroMinWidth: true, children: _jsx("div", { className: classes.tenant, children: _jsx(TenantSelector, {}) }) }, "tenant")), itemDefs.filter(shouldRenderItem).map((item, idx) => (_jsxs(Grid, { item: true, children: [idx !== 0 && _jsx(Divider, {}), _jsx(SamedisMenuItem, { item: item, vertical: vertical, menuItemId: menuItemId })] }, item.route || idx)))] }) }));
};
export default React.memo(SamedisMenuGroup);
